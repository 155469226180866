import React from "react";
import {
	Row,
	Col,
	Container,
	Form,
	InputGroup,
	Button,
	Tab,
	Nav,
	ButtonToolbar,
	ToggleButton,
	ToggleButtonGroup,
	Image,
	OverlayTrigger,
	Tooltip,
	Badge,
	ThemeProvider,
	Card,
	Alert,
	Spinner,
} from "react-bootstrap";
import {
	Elements,
	ElementsConsumer,
	ExpressCheckoutElement,
	PaymentElement,
} from "@stripe/react-stripe-js";

class StripeForm extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.elementOptions = {
			mode: "payment",
			amount: this.props.price,
			currency: this.props.currency.toLowerCase(),
		};
		// console.log(this.props.price);
		this.state = { formFillCompleted: false, expressCheckoutShown: false };
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmitError = this.handleSubmitError.bind(this);
	}

	handleChange = (event) => {
		this.setState({ formFillCompleted: event.complete });
		this.props.setStripeError(null);
	};

	handleSubmitError = (error) => {
		this.props.setStripeError("An Error Occured");
	};

	render() {
		return (
			<Container className="d-flex justify-content-center">
				<Card
					style={{
						width: "30rem",
						// boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
						// borderBottomRightRadius: 50,
						// borderTopLeftRadius: 50,
						borderRadius: 20,
						background: "#f3f7f8",
						// borderColor: "#f3f7f8",
					}}
				>
					<Card.Body>
						<Card.Title
							style={{
								display: "flex",
								justifyContent: "center",
								color: "grey",
								textAlign: "center",
								fontSize: 15,
								marginBottom: 20,
							}}
						>
							Payment is secured by Stripe. Pay using Apple Pay,
							Google Pay or Credit Card
						</Card.Title>
						<Elements
							stripe={this.props.stripePromise}
							options={{
								...this.elementOptions,
								appearance: {
									theme: "stripe",
									variables: {
										// fontWeightNormal: "500",
										borderRadius: "10px",
										colorPrimary: "#0a2156",
										// tabIconSelectedColor: "#ffffff",
										gridRowSpacing: "18px",
									},
									// rules: {
									// 	".AccordionItem": {
									// 		backgroundColor: "#f3f7f8",
									// 		borderColor: "#f3f7f8",
									// 		boxShadow: "none",
									// 	},
									// },
								},
							}}
						>
							<ElementsConsumer>
								{({ stripe, elements }) => {
									const handleSubmit = async (event) => {
										event.preventDefault();
										if (!stripe || !elements) {
											// Stripe.js hasn't yet loaded.
											// Make  sure to disable form submission until Stripe.js has loaded.
											return;
										}
										this.props.setStripeLoading(true);
										this.props.setStripeError(null);

										const { error: submitError } =
											await elements.submit();
										if (submitError) {
											this.handleSubmitError(submitError);
											return;
										}

										// Create the PaymentIntent and obtain clientSecret
										this.props.setStripePaymentUsingIntent({
											stripe,
											elements,
										});
									};
									return (
										<>
											{/* <ExpressCheckoutElement
												onConfirm={handleSubmit}
												// options={{
												// 	wallets: {
												// 		googlePay: "always",
												// 	},
												// }}
												onReady={(e) => {
													if (e.availablePaymentMethods) {
														this.setState({
															expressCheckoutShown: true,
														});
													}
												}}
											/> */}
											{this.state.expressCheckoutShown && (
												<span
													style={{
														display: "flex",
														marginTop: 15,
														marginBottom: 15,
														justifyContent: "center",
													}}
												>
													_____ Or pay with card _____
												</span>
											)}
											<form onSubmit={handleSubmit}>
												<PaymentElement
													options={{
														readOnly:
															this.props
																.stripeLoading,
														layout: {
															type: "tabs",
														},
														paymentMethodOrder: [
															"google_pay",
															"apple_pay",
															"card",
														],
														business: {
															name: this.props
																.businessName,
														},
														defaultValues: {
															billingDetails: {
																email:
																	this.props
																		.customerDetails
																		?.email ||
																	"",
																name:
																	this.props
																		.customerDetails
																		?.first_name ||
																	"",
																phone:
																	this.props
																		.customerDetails
																		?.phone ||
																	"",
															},
														},
														// wallets: {
														// 	googlePay: "never",
														// 	applePay: "never",
														// },
														// fields: {
														// 	billingDetails: {
														// 		address: {
														// 			country:
														// 				"never",
														// 			postalCode:
														// 				"never",
														// 			city: "never",
														// 			state: "never",
														// 			line1: "never",
														// 			line2: "never",
														// 		},
														// 	},
														// },
													}}
													onChange={this.handleChange}
												/>
												<span
													style={{
														textAlign: "center",
														width: "100%",
														display: "block",
														marginTop: 10,
														color: "red",
													}}
												>
													{this.props.stripeError}
												</span>
												<Button
													id="submit"
													type="submit"
													className="stripe-pay-button"
													disabled={
														!stripe ||
														this.props.stripeLoading ||
														!this.state
															.formFillCompleted ||
														!Boolean(
															this.props?.cart_id
														)
													}
													style={{
														width: "100%",
														marginTop: 20,
														marginBottom: 10,
														borderRadius: 10,
													}}
												>
													<span id="button-text">
														{this.props
															.stripeLoading ? (
															<Spinner
																size="sm"
																animation="border"
																role="status"
																id="spinner"
															></Spinner>
														) : (
															`Pay Now $${this.props.rawPrice}`
														)}
													</span>
												</Button>
												<Button
													onClick={(e) => {
														e.preventDefault();
														this.props.setStripeFormShow(
															false
														);
													}}
													className="stripe-pay-button"
													style={{
														width: "100%",
														marginTop: 20,
														marginBottom: 10,
														borderRadius: 10,
													}}
												>
													<span id="button-text">
														Cancel
													</span>
												</Button>
											</form>
										</>
									);
								}}
							</ElementsConsumer>
						</Elements>
					</Card.Body>
				</Card>
			</Container>
		);
	}
}

export default StripeForm;
