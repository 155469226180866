function getDiscountFromRanges(offerRuleCopy, totalCartAmount) {
	let nearestRange = null;
	let discount = "0.00";
	offerRuleCopy.destinations[0].discount_ranges.forEach((range) => {
		if (
			Number(totalCartAmount) >= Number(range.min_order_value) &&
			(!nearestRange ||
				Number(range.min_order_value) >
					Number(nearestRange.min_order_value))
		) {
			nearestRange = range;
		}
	});
	if (nearestRange) {
		discount = nearestRange.discount;
	}
	return discount;
}

function getValidOfferRules(offerRules, totalCartAmount, isApplyPerItem = true) {
	const validOfferRules = [];

	offerRules.forEach((offerRule) => {
		const offerRuleCopy = { ...offerRule };
		if (isApplyPerItem) {
			if (offerRuleCopy.is_apply_per_item === true) {
				if (offerRuleCopy.offer_mode === 3) {
					offerRuleCopy.offer_mode = 2;
					offerRuleCopy.destinations[0].discount = getDiscountFromRanges(
						offerRuleCopy,
						totalCartAmount
					);
				}
				const isValid = checkOfferRuleConditions(offerRuleCopy);

				if (isValid) {
					validOfferRules.push(offerRuleCopy);
				}
			}
		} else {
			if (
				offerRuleCopy.is_apply_per_item === false &&
				offerRuleCopy.offer_mode === 3 &&
				validOfferRules.length === 0
			) {
				offerRuleCopy.destinations[0].discount = getDiscountFromRanges(
					offerRuleCopy,
					totalCartAmount
				);
				validOfferRules.push(offerRuleCopy);
			}
		}
	});
	return validOfferRules;
}

function checkOfferRuleConditions(offerRule) {
	const source = offerRule.sources[0];
	const destination = offerRule.destinations[0];

	if (!source || !destination) {
		return false;
	}

	const isValidSource = () => {
		if (
			(source.source_type === "category" &&
				source.source_categories.length) ||
			(source.source_type === "item" && source.source_items.length)
		) {
			if (source.is_count_based && source.count <= 0) {
				return false;
			}
			if (
				source.is_price_based &&
				(!source.price || parseFloat(source.price) <= 0)
			) {
				return false;
			}
			return true;
		}
		return false;
	};

	const isValidDestination = () => {
		if (
			(destination.destination_type === "category" &&
				destination.destination_categories.length) ||
			(destination.destination_type === "item" &&
				destination.destination_items.length)
		) {
			if (
				destination.count <= 0 ||
				!destination.discount ||
				parseFloat(destination.discount) <= 0
			) {
				return false;
			}
			if (
				destination.discount_type !== "fixed" &&
				parseFloat(destination.discount) > 100
			) {
				return false;
			}
			return true;
		}
		return false;
	};
	if (offerRule.offer_mode === 2) {
		return isValidDestination();
	}

	return isValidSource() && isValidDestination();
}

function getFinalCartDiscountAmount(offerRules, totalCartAmount) {
	let discount = "0.00";
	try {
		// let totalCartAmount = cart.reduce((acc, item) => {
		// 	return acc + parseFloat(item.price) * item.quantity;
		// }, 0);
		let validOfferRules = getValidOfferRules(
			offerRules,
			totalCartAmount,
			false
		);
		if (validOfferRules && validOfferRules.length > 0) {
			discount = validOfferRules[0].destinations[0].discount;
			if (validOfferRules[0].destinations[0].discount_type === "percentage") {
				discount = (totalCartAmount * discount) / 100;
			}
		}
		return discount;
	} catch (e) {
		return "0.00";
	}
}

function resetCart(existingCart) {
	return existingCart.map((item, index) => ({
		...item,
		totalOfferDiscount: 0,
		totalOfferDestinationQuantity: 0,
		totalOfferSourceQuantity: 0,
		offerRuleIds: [], // Initialize as an empty array for unprocessed items
		offers: [],
		// offerDetails: {}, // Initialize as an empty object for discount details
		originalIndex: index, // Track the original index
		originalQuantity: item.originalQuantity || item.quantity,
	}));
}

function createCartItemsOfferPairs(cart) {
	let groupedCartItems = {};
	let ungroupedCartItems = [];
	let processedCartItems = [];
	try {
		for (let i = 0; i < cart.length; i++) {
			let cartItem = cart[i];

			let { offers = [] } = cartItem;
			let usedSource = 0;
			let usedDestination = 0;
			if (offers?.length > 0) {
				for (let offer of offers) {
					let {
						id: offerId,
						totalOfferSourceQuantity,
						totalOfferDestinationQuantity,
						totalOfferDiscount,
					} = offer;

					if (!(offerId in groupedCartItems)) {
						groupedCartItems[offerId] = {
							sourceItems: [],
							destinationItems: [],
						};
					}

					if (totalOfferSourceQuantity > 0) {
						groupedCartItems[offerId].sourceItems.push({
							...cartItem,
							quantity: totalOfferSourceQuantity,
							totalOfferSourceQuantity,
							totalOfferDestinationQuantity: 0,
							totalOfferDiscount: "0.00",
							offerRule: offerId,
						});
					}
					if (totalOfferDestinationQuantity > 0) {
						groupedCartItems[offerId].destinationItems.push({
							...cartItem,
							quantity: totalOfferDestinationQuantity,
							totalOfferDestinationQuantity,
							totalOfferSourceQuantity: 0,
							totalOfferDiscount: totalOfferDiscount,
							offerRule: offerId,
						});
					}

					usedSource += totalOfferSourceQuantity;
					usedDestination += totalOfferDestinationQuantity;
				}
				if (cartItem.quantity > usedSource + usedDestination) {
					ungroupedCartItems.push({
						...cartItem,
						quantity: cartItem.quantity - usedSource - usedDestination,
						totalOfferDiscount: "0.00",
						totalOfferSourceQuantity: 0,
						totalOfferDestinationQuantity: 0,
					});
				}
			} else {
				ungroupedCartItems.push({
					...cartItem,
					totalOfferDiscount: "0.00",
					totalOfferSourceQuantity: 0,
					totalOfferDestinationQuantity: 0,
				});
			}
		}

		for (let offerId in groupedCartItems) {
			let { sourceItems, destinationItems } = groupedCartItems[offerId];
			processedCartItems = processedCartItems.concat(
				sourceItems,
				destinationItems
			);
		}
		processedCartItems = processedCartItems.concat(ungroupedCartItems);

		// verification
		let totalRealCartItemQuantity = 0;
		for (let i = 0; i < cart.length; i++) {
			totalRealCartItemQuantity += cart[i].quantity;
		}
		let totalProcessedCartItemQuantity = 0;
		for (let i = 0; i < processedCartItems.length; i++) {
			totalProcessedCartItemQuantity += processedCartItems[i].quantity;
		}
		if (totalRealCartItemQuantity !== totalProcessedCartItemQuantity) {
			throw new Error("Something went wrong!");
		}
	} catch (e) {
		processedCartItems = cart;
	}

	return processedCartItems;
}

function getFinalItemPrice(item) {
	let finalPrice = parseFloat(item.itemPrice);
	try {
		let discount = parseFloat(item.discount);
		if (discount > 0) {
			discount = (finalPrice * discount) / 100;
			finalPrice = finalPrice - discount;
		}
	} catch (e) {
		finalPrice = parseFloat(item.itemPrice);
	}
	return finalPrice.toFixed(2);
}

class OfferEngine {
	constructor(offerRules, originalCart) {
		this.suppliedOfferRules = offerRules;
		this.totalCartAmount = originalCart.reduce((acc, item) => {
			return acc + parseFloat(getFinalItemPrice(item)) * item.quantity;
		}, 0);
		this.offerRules = getValidOfferRules(
			offerRules,
			this.totalCartAmount,
			true
		);
		this.originalCart = originalCart;

		// for split by price
		this.balance = 0;
	}

	applyOffers(existingCart) {
		let cart = this.initializeCart(existingCart);
		let finalCartPart = {};

		// if (this.offerRules.length === 0) {
		// 	return cart;
		// }

		this.offerRules.forEach((rule) => {
			let totalRuleDestinationQuantity = 0;
			let totalRuleSourceQuantity = 0;
			const { source, destination } = this.getSourceAndDestination(rule);
			let sourceMetrics = {
				totalSourceItemCount: 0,
				totalSourceItemPrice: 0,
			};

			if (rule.offer_mode === 2) {
				this.applyDiscount(cart, rule, source, destination);
			} else {
				sourceMetrics = this.calculateSourceMetrics(cart, source);
				let index = 0;
				while (this.shouldApplyDiscount(source, sourceMetrics)) {
					index++;
					if (index > 4000) {
						throw new Error("Something went wrong!");
					}
					this.applyDiscount(cart, rule, source, destination);
					sourceMetrics = this.calculateSourceMetrics(cart, source);
				}
			}
			cart = this.mergeSplitItems(cart);
			cart = this.calculateSourceAppliedCount(cart, rule);

			cart.forEach((item, index) => {
				let remainingQuantity =
					item.quantity -
					(item.totalOfferDestinationQuantity +
						item.totalOfferSourceQuantity);

				if (!(index in finalCartPart)) {
					finalCartPart[index] = {};
				}

				if (!("totalOfferDestinationQuantity" in finalCartPart[index])) {
					finalCartPart[index].totalOfferDestinationQuantity =
						item.totalOfferDestinationQuantity;
				} else {
					finalCartPart[index].totalOfferDestinationQuantity =
						item.totalOfferDestinationQuantity +
						finalCartPart[index].totalOfferDestinationQuantity;
				}
				if (!("totalOfferSourceQuantity" in finalCartPart[index])) {
					finalCartPart[index].totalOfferSourceQuantity =
						item.totalOfferSourceQuantity;
				} else {
					finalCartPart[index].totalOfferSourceQuantity =
						item.totalOfferSourceQuantity +
						finalCartPart[index].totalOfferSourceQuantity;
				}
				if (!("totalOfferDiscount" in finalCartPart[index])) {
					finalCartPart[index].totalOfferDiscount =
						item.totalOfferDiscount;
				} else {
					finalCartPart[index].totalOfferDiscount =
						item.totalOfferDiscount +
						finalCartPart[index].totalOfferDiscount;
				}

				//
				if (!("offers" in finalCartPart[index])) {
					finalCartPart[index].offers = [
						{
							id: rule.id,
							totalOfferDiscount: item.totalOfferDiscount.toFixed(2),
							totalOfferSourceQuantity: item.totalOfferSourceQuantity,
							totalOfferDestinationQuantity:
								item.totalOfferDestinationQuantity,
						},
					];
				} else {
					finalCartPart[index].offers.push({
						id: rule.id,
						totalOfferDiscount: item.totalOfferDiscount.toFixed(2),
						totalOfferSourceQuantity: item.totalOfferSourceQuantity,
						totalOfferDestinationQuantity:
							item.totalOfferDestinationQuantity,
					});
				}

				item.quantity = remainingQuantity;
				if (remainingQuantity < 0) {
					throw new Error("Something went wrong!");
				}
				totalRuleDestinationQuantity += item.totalOfferDestinationQuantity;
				totalRuleSourceQuantity += item.totalOfferSourceQuantity;
			});
			if (rule.offer_mode !== 2) {
				this.updateSuppliedOfferRules(
					rule,
					{
						totalRuleDestinationQuantity,
						totalRuleSourceQuantity,
					},
					sourceMetrics
				);
			}

			cart = this.initializeCart(cart);

			// Reset items that were partially processed but didn't fully qualify for the discount
			// this.resetFailedRule(cart, rule);
		});

		// Merge split items back to their original state
		// cart = this.mergeSplitItems(cart);
		cart.forEach((item, index) => {
			item.quantity = item.originalQuantity;
			item.originalQuantity = null;
			if (finalCartPart[index]) {
				item.totalOfferDestinationQuantity =
					finalCartPart[index].totalOfferDestinationQuantity;
				item.totalOfferSourceQuantity =
					finalCartPart[index].totalOfferSourceQuantity;

				item.totalOfferDiscount =
					finalCartPart[index].totalOfferDiscount.toFixed(2);
				item.offers = finalCartPart[index].offers;
			}

			if (
				parseFloat(item.totalOfferDiscount) >
				parseFloat(item.price) * item.quantity
			) {
				throw new Error("Something went wrong!");
			}
		});

		this.verifyFinalCart(cart);

		return cart;
	}

	updateSuppliedOfferRules(
		{ id, sources },
		{ totalRuleDestinationQuantity, totalRuleSourceQuantity },
		sourceMetrics
	) {
		let sourceImpacted = false;
		if (sources[0].is_count_based) {
			sourceImpacted = sourceMetrics.totalSourceItemCount > 0;
		} else {
			sourceImpacted = sourceMetrics.totalSourceItemPrice > 0;
		}
		const rule = this.suppliedOfferRules.find((rule) => rule.id === id);
		if (rule) {
			if (!rule.showNotification) {
				if (!totalRuleDestinationQuantity) {
					rule.alreadyNotified = false;
				}
				if (sourceImpacted && !rule.alreadyNotified) {
					rule.showNotification = rule.notification_trigger;
					rule.alreadyNotified = true;
				}
			} else {
				if (!sourceImpacted || totalRuleDestinationQuantity > 0) {
					rule.showNotification = false;
				}
			}

			return true;
		}
		return false;
	}

	getUpdatedOfferRules() {
		return this.suppliedOfferRules;
	}

	verifyFinalCart(finalCart) {
		let originalCart = this.originalCart;
		if (!(finalCart.length === originalCart.length)) {
			throw new Error("Something went wrong!");
		}
		let totalDiscount = 0;
		let totalPrice = 0;

		for (let i = 0; i < originalCart.length; i++) {
			if (
				originalCart[i].quantity !== finalCart[i].quantity ||
				originalCart[i].id !== finalCart[i].id
			) {
				throw new Error("Something went wrong!");
			}
			totalDiscount += finalCart[i].totalOfferDiscount;
			totalPrice += parseFloat(finalCart[i].price) * finalCart[i].quantity;
		}
		if (totalDiscount > totalPrice) {
			throw new Error("Total discount offer amount is not valid!");
		}

		return true;
	}

	calculateSourceAppliedCount(cart, rule) {
		const cartCopy = cart.map((item) => ({
			...item,
			totalOfferSourceQuantity: 0,
		}));

		if (rule.offer_mode === 2) {
			return cartCopy;
		}

		const { source, destination } = this.getSourceAndDestination(rule);

		let remainingDiscountedQuantity = 0;
		cartCopy.forEach((item) => {
			if (this.isDestinationItem(item, destination)) {
				remainingDiscountedQuantity += item.totalOfferDestinationQuantity;
			}
		});

		let discountAppliedTimes = Math.floor(
			remainingDiscountedQuantity / destination.count
		);

		let balance = remainingDiscountedQuantity % destination.count;

		if (source.is_count_based) {
			let sourceUsed = discountAppliedTimes * source.count;

			if (discountAppliedTimes === 0 && remainingDiscountedQuantity > 0) {
				sourceUsed = source.count;
			} else if (balance) {
				sourceUsed += source.count; // Adjust to source count
			}

			cartCopy.forEach((item) => {
				if (this.isSourceItem(item, source)) {
					let itemQuantity = item.totalOfferDestinationQuantity
						? item.quantity - item.totalOfferDestinationQuantity
						: item.quantity;
					const applicableQuantity = Math.min(itemQuantity, sourceUsed);
					item.totalOfferSourceQuantity += applicableQuantity;
					sourceUsed -= applicableQuantity;
				}
			});
		} else {
			let totalSourceAmountUsed =
				discountAppliedTimes * parseFloat(source.price);

			if (discountAppliedTimes === 0 && remainingDiscountedQuantity > 0) {
				totalSourceAmountUsed = parseFloat(source.price);
			} else if (balance) {
				totalSourceAmountUsed += parseFloat(source.price);
			}

			cartCopy.forEach((item) => {
				if (this.isSourceItem(item, source)) {
					let itemQuantity = item.totalOfferDestinationQuantity
						? item.quantity - item.totalOfferDestinationQuantity
						: item.quantity;
					let itemPrice = parseFloat(item.price);

					let applicableQuantity = Math.min(
						itemQuantity,
						Math.ceil(totalSourceAmountUsed / itemPrice)
					);

					item.totalOfferSourceQuantity += applicableQuantity;
					totalSourceAmountUsed -= applicableQuantity * itemPrice;
				}
			});
		}

		return cartCopy;
	}

	initializeCart(existingCart) {
		return resetCart(existingCart);
	}

	getSourceAndDestination(rule) {
		const source = rule?.sources?.[0] || {};
		const destination = rule.destinations[0];
		return { source, destination };
	}

	calculateSourceMetrics(cart, source) {
		let totalSourceItemCount = 0;
		let totalSourceItemPrice = 0;

		cart.forEach((item) => {
			if (!item.offerRuleIds.length && this.isSourceItem(item, source)) {
				totalSourceItemCount += item.quantity;
				totalSourceItemPrice += parseFloat(item.price) * item.quantity;
			}
		});
		// alert(this.balance);

		totalSourceItemPrice += this.balance;

		return { totalSourceItemCount, totalSourceItemPrice };
	}

	isSourceItem(item, source) {
		return (
			((source.source_type === "category" &&
				source.source_categories.includes(item.category_id)) ||
				(source.source_type === "item" &&
					source.source_items.includes(item.id))) &&
			item.quantity &&
			!item.isFree
		);
	}

	shouldApplyDiscount(source, { totalSourceItemCount, totalSourceItemPrice }) {
		return (
			(source.is_count_based && totalSourceItemCount >= source.count) ||
			(source.is_price_based && totalSourceItemPrice >= source.price)
		);
	}

	applyDiscount(
		cart,
		rule,
		source,
		destination
		// { totalSourceItemCount, totalSourceItemPrice }
	) {
		if (rule.offer_mode !== 2) {
			if (source.is_count_based) {
				this.splitSourceItems(cart, source.count, rule.id, source);
			} else if (source.is_price_based) {
				this.splitSourceItemsByPrice(cart, source.price, rule.id, source);
			}
		}

		this.applyDiscountToItems(cart, rule, destination);
	}

	splitSourceItems(cart, count, ruleId, source) {
		let remainingCount = count;

		cart.forEach((item, index) => {
			if (remainingCount <= 0) return;

			if (this.isSourceItem(item, source) && !item.offerRuleIds.length) {
				if (item.quantity > remainingCount) {
					const remainingQuantity = item.quantity - remainingCount;
					item.quantity = remainingCount;
					item.offerRuleIds.push(ruleId); // Mark the original item

					// Create a new item with the remaining quantity
					cart.splice(index + 1, 0, {
						...item,
						quantity: remainingQuantity,
						offerRuleIds: [], // Ensure new item is not marked
						originalIndex: item.originalIndex, // Keep the original index
						// offerDetails: {}, // Initialize discount details for the new item
					});

					remainingCount = 0;
				} else {
					remainingCount -= item.quantity;
					item.offerRuleIds.push(ruleId); // Mark the original item
				}
			}
		});
	}

	splitSourceItemsByPrice(cart, price, ruleId, source) {
		let remainingPrice = price;
		cart.forEach((item, index) => {
			if (remainingPrice <= 0) return;

			const itemTotalPrice =
				parseFloat(item.price) * item.quantity + this.balance;
			// if (itemTotalPrice <= remainingPrice) {
			//     itemTotalPrice+=this.balance;
			// }
			if (this.isSourceItem(item, source) && !item.offerRuleIds.length) {
				if (itemTotalPrice > remainingPrice) {
					const requiredQuantity = Math.ceil(
						remainingPrice / (parseFloat(item.price) + this.balance)
					);
					this.balance +=
						parseFloat(item.price) * requiredQuantity - remainingPrice;

					const remainingQuantity = item.quantity - requiredQuantity;

					item.quantity = requiredQuantity;
					item.offerRuleIds.push(ruleId); // Mark the original item

					// Create a new item with the remaining quantity
					if (remainingQuantity > 0) {
						cart.splice(index + 1, 0, {
							...item,
							quantity: remainingQuantity,
							offerRuleIds: [], // Ensure new item is not marked
							originalIndex: item.originalIndex, // Keep the original index
							// offerDetails: {}, // Initialize discount details for the new item
						});
					}

					remainingPrice = 0;
				} else {
					remainingPrice -= itemTotalPrice;
					item.offerRuleIds.push(ruleId); // Mark the original item
				}
			}
		});
	}

	applyDiscountToItems(cart, rule, destination) {
		let discountAppliedCount = 0;

		cart.forEach((item, index) => {
			if (discountAppliedCount >= destination.count) return;

			if (
				this.isDestinationItem(item, destination) &&
				!item.offerRuleIds.length
			) {
				const remainingQuantity =
					rule.offer_mode === 2
						? item.quantity
						: destination.count - discountAppliedCount;
				const discountableQuantity = Math.min(
					item.quantity,
					remainingQuantity
				);

				if (discountableQuantity < item.quantity) {
					// Split the item into two parts
					const remainingQuantity = item.quantity - discountableQuantity;
					item.quantity = discountableQuantity;

					// Create a new item with the remaining quantity
					cart.splice(index + 1, 0, {
						...item,
						quantity: remainingQuantity,
						offerRuleIds: [], // Ensure new item is not marked
						originalIndex: item.originalIndex, // Keep the original index
						// offerDetails: {}, // Initialize discount details for the new item
					});
				}

				this.applyDiscountToItem(
					item,
					rule,
					discountableQuantity,
					destination
				);
				if (rule.offer_mode !== 2) {
					discountAppliedCount += discountableQuantity;
				}
			}
		});
	}

	isDestinationItem(item, destination) {
		return (
			((destination.destination_type === "category" &&
				destination.destination_categories.includes(item.category_id)) ||
				(destination.destination_type === "item" &&
					destination.destination_items.includes(item.id))) &&
			item.quantity &&
			!item.isFree
		);
	}

	applyDiscountToItem(item, rule, discountableQuantity, destination) {
		const discount = parseFloat(destination.discount);
		let discountAmount =
			destination.discount_type === "fixed"
				? discount <= parseFloat(getFinalItemPrice(item))
					? discount * discountableQuantity
					: parseFloat(getFinalItemPrice(item)) * discountableQuantity
				: parseFloat(getFinalItemPrice(item)) *
				  (discount / 100) *
				  discountableQuantity;

		item.totalOfferDiscount += discountAmount;
		item.totalOfferDestinationQuantity += discountableQuantity;
		item.offerRuleIds.push(rule.id); // Add the offer rule ID to the array

		// Update discount details for the offer rule
		// if (!item.offerDetails[rule.id]) {
		// 	item.offerDetails[rule.id] = {
		// 		totalDiscount: 0,
		// 		totalDestinationQuantity: 0,
		// 	};
		// }
		// item.offerDetails[rule.id].totalDiscount += discountAmount;
		// item.offerDetails[rule.id].totalDestinationQuantity += discountableQuantity;
	}

	// resetFailedRule(cart, rule) {
	// 	cart.forEach((item) => {
	// 		if (
	// 			item.offerRuleIds.includes(rule.id) &&
	// 			item.totalOfferDestinationQuantity === 0
	// 		) {
	// 			this.resetItem(item, rule.id);
	// 		}
	// 	});
	// }

	// resetItem(item, ruleId) {
	// 	item.totalOfferDiscount = 0;
	// 	item.totalOfferDestinationQuantity = 0;
	// 	item.offerRuleIds = item.offerRuleIds.filter((id) => id !== ruleId);
	// 	delete item.offerDetails[ruleId];
	// }

	mergeSplitItems(cart) {
		const mergedCart = [];

		cart.forEach((item) => {
			const existingItem = mergedCart.find(
				(mergedItem) => mergedItem.originalIndex === item.originalIndex
			);

			if (existingItem) {
				existingItem.quantity += item.quantity;
				existingItem.totalOfferDiscount += item.totalOfferDiscount;
				existingItem.totalOfferDestinationQuantity +=
					item.totalOfferDestinationQuantity;
				existingItem.offerRuleIds = [
					...new Set([
						...existingItem.offerRuleIds,
						...item.offerRuleIds,
					]),
				];

				// Merge discount details
				// for (const [ruleId, details] of Object.entries(item.offerDetails)) {
				// 	if (!existingItem.offerDetails[ruleId]) {
				// 		existingItem.offerDetails[ruleId] = {
				// 			totalDiscount: 0,
				// 			totalDestinationQuantity: 0,
				// 		};
				// 	}
				// 	existingItem.offerDetails[ruleId].totalDiscount +=
				// 		details.totalDiscount;
				// 	existingItem.offerDetails[ruleId].totalDestinationQuantity +=
				// 		details.totalDestinationQuantity;
				// }
			} else {
				mergedCart.push({ ...item });
			}
		});

		return mergedCart;
	}
}

export { resetCart, createCartItemsOfferPairs, getFinalCartDiscountAmount };

export default OfferEngine;
