import React from "react";
import { Modal, Spinner } from "react-bootstrap";

class FullScreenLoader extends React.Component {
	render() {
		return (
			<Modal
				show={this.props.show}
				backdrop="static"
				keyboard={false}
				centered
				size="lg"
				className="fullscreen-loader"
			>
				<Modal.Body className="d-flex justify-content-center align-items-center">
					<Spinner animation="border" role="status">
						{/* <span className="sr-only">Loading...</span> */}
					</Spinner>
				</Modal.Body>
			</Modal>
		);
	}
}

export default FullScreenLoader;
