import React from "react";
import {
	Row,
	Col,
	Container,
	Collapse,
	Tab,
	Nav,
	Form,
	InputGroup,
	Modal,
	ButtonToolbar,
	Button,
	ToggleButton,
	ToggleButtonGroup,
	Image,
	Card,
} from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import Icofont from "react-icofont";
import QuickBite from "../common/QuickBite";
import { baseURL } from "../../helpers/globalConstants";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Media } from "react-bootstrap";
import CheckoutItem from "../common/CardItem";
import { formatSpace } from "../../services/DataFormatHandler";
import DataCollapse from "./CollapseData";
import VariantTab from "../common/VariantTab";
const NOT_CHECKED = 0,
	CHECKED_EROR = 1,
	SUCESS = 2;
const SPAN_STATUS = [
	"text-dark float-right",
	"text-danger float-right",
	"text-primary float-right",
];
const capitalizeName = (name) => {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase()).replace(/'S\b/g, "'s");
};
class ViewMenuDiscriptionModal extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			firstTab: "first",
			quantity: 1,
			item_suggestion: "",
			addStatus: true,
			tabKey: "0",
			tabOpen: true,
			erorMessages: [],
			erorSuggestions: [],
			upCellItems: [],
			customAnswers: [],
			selectedVariant: null,
			variantTabKey: "variant",
		};
		this.isIn = this.isIn.bind(this);
		this.isInMax = this.isInMax.bind(this);
		this.setCustomaize = this.setCustomaize.bind(this);
		this.answerFormHandler = this.answerFormHandler.bind(this);
		this.upcellItemFormHandler = this.upcellItemFormHandler.bind(this);
	}

	handleVariantChange = (variant) => {
		this.setState({
			selectedVariant: variant,
			variantTabKey: null,
			upCellItems: [],
			quantity: 1,
			tabKey: "0",
			tabOpen: true,
			addStatus: true,
			erorMessages: [],
			erorSuggestions: [],

			item_suggestion: "",
			firstTab: "",
			customAnswers: [],
		});
		this.props.handleVariantSelection(
			variant
				? {
						...variant,
						title:
							this.props.parentVariantDish.title +
							" - " +
							variant.variant_child_title,
				  }
				: null
		);
	};

	handleSuggestion = (e) => {
		let isuggestion = e.target.value;
		this.setState({ item_suggestion: isuggestion });
	};

	getTotalPrice = (cartItem) => {
		let total = 0;
		if (this.state.upCellItems) {
			total = this.state.upCellItems.reduce(function (previous, current) {
				return previous + +(current.item_price * current.quantity);
			}, 0);
		}
		let answerTotal = 0;
		if (this.state.customAnswers) {
			answerTotal = this.state.customAnswers.reduce(function (
				previous,
				current
			) {
				return previous + +current.item_price;
			},
			0);
			total = total + answerTotal + Number(cartItem.price);
		}
		total = Number(total).toFixed(2);
		return total;
	};
	setCustomaize = () => {
		let id = this.props.itemSelected.id;
		let cartItem = {
			id: this.props.itemSelected.id,
			discount: this.props.itemSelected.discount,
			title: this.props.itemSelected.title,
			price: this.props.isComboItemView
				? "0.00"
				: this.props.itemSelected.price,
			is_delivery_enabled: this.props.itemSelected.is_delivery_enabled,
			is_takeout_enabled: this.props.itemSelected.is_takeout_enabled,
			quantity: this.state.quantity,
			item_suggestion: this.state.item_suggestion,
			upCellItems: this.state.upCellItems,
			customAnswers: this.state.customAnswers,
			part_of_combo: this.props.isComboItemView || false,
			tax_percent: this.props.itemSelected.tax_percent || 0,
			parent_variant_dish_id: this.props?.parentVariantDish?.id || null,
		};
		// console.log("Added Items", cartItem);
		// console.log("Selected Item", this.props.itemSelected);

		this.props.setCustomaize(cartItem);
		this.setState({
			upCellItems: [],
			quantity: 1,
			tabKey: "0",
			tabOpen: true,
			addStatus: true,
			erorMessages: [],
			erorSuggestions: [],

			item_suggestion: "",
			firstTab: "",
			customAnswers: [],
			variantTabKey: "variant",
			selectedVariant: null,
		});
	};
	findTotalQuantity = (inputTab) => {
		var quantityTotal = 0;
		inputTab.map((obj) => {
			quantityTotal = quantityTotal + obj.quantity;
		});
		return quantityTotal;
	};
	isInMax = (sugestionObj) => {
		var selectedItems = this.state.customAnswers.filter(
			(answerObj) => answerObj.owner_tab == sugestionObj.id
		);
		var itemLength = selectedItems.length;
		if (sugestionObj.enable_quantity_limits == true && itemLength > 0) {
			var maxStatus = itemLength < sugestionObj.max_number_of_items_required;
			return maxStatus;
		} else {
			return true;
		}
	};
	isAddAble = () => {
		var addStatus = true;
		var erorMessages = [];
		var erorSuggestions = [];

		this.props.itemSelected.custom_sugestions.map((sugestionObj) => {
			var requiredStatus = true;
			var limitStatus = true;
			var selectedItems =
				sugestionObj.tab_type == 1
					? this.state.upCellItems.filter(
							(upcellObj) => upcellObj.owner == sugestionObj.id
					  )
					: this.state.customAnswers.filter(
							(answerObj) => answerObj.owner_tab == sugestionObj.id
					  );
			var itemLength =
				sugestionObj.tab_type == 1
					? this.findTotalQuantity(selectedItems)
					: selectedItems.length;

			if (sugestionObj.is_mandatory == true) {
				if (!selectedItems || selectedItems.length == 0) {
					requiredStatus = false;
					var message = "Please fill mandatory tab " + sugestionObj.title;
					erorMessages.push(message);
					erorSuggestions.push(sugestionObj.id);
				}
			}
			if (
				sugestionObj.enable_quantity_limits == true &&
				itemLength > 0 &&
				requiredStatus == true
			) {
				var minStatus = true;
				var maxStatus = true;
				minStatus = itemLength >= sugestionObj.number_of_items_required;
				if (minStatus == false) {
					var message =
						"Please select atleast " +
						sugestionObj.number_of_items_required +
						" items from the tab " +
						sugestionObj.title;
					erorSuggestions.push(sugestionObj.id);

					erorMessages.push(message);
					limitStatus = false;
				} else {
					maxStatus =
						itemLength <= sugestionObj.max_number_of_items_required;
					if (maxStatus == false) {
						var message =
							"You can select only " +
							sugestionObj.max_number_of_items_required +
							" items from the tab " +
							sugestionObj.title;
						erorMessages.push(message);
						erorSuggestions.push(sugestionObj.id);

						limitStatus = false;
					}
				}
			}
			addStatus = addStatus && limitStatus && requiredStatus;

			console.log("Add Status", addStatus);
		});
		this.setState({
			addStatus: addStatus,
			erorMessages: erorMessages,
			erorSuggestions: erorSuggestions,
		});
		return addStatus;
	};
	isValidWith = () => {};
	answerFormHandler = (event, customObj, callBack) => {
		let key = "";
		let value = "";
		console.log("Created event", event);
		let inputForm = [...this.state.customAnswers];
		if (event.target.type == "checkbox") {
			key = event.target.name;
			value = event.target.value;

			if (event.target.checked == true) {
				if (!inputForm.includes(customObj)) {
					inputForm.push(customObj);
				}
			} else {
				inputForm.splice(inputForm.indexOf(customObj), 1);
			}
		}

		if (event.target.type == "radio") {
			key = event.target.name;
			value = event.target.value;
			console.log("Radio Choices", customObj);
			var pos = inputForm.findIndex((obj) => customObj.owner == obj.owner);
			console.log("Position", pos);
			if (pos == -1) {
				inputForm.push(customObj);
			} else {
				inputForm[pos] = customObj;
			}
		}
		this.setState({ customAnswers: inputForm }, () => {
			console.log("Answers", this.state.customAnswers);
			callBack();
			this.isAddAble();
		});
	};

	findIndex(customObj) {
		let inputForm = [...this.state.upCellItems];
		let index = -1;
		inputForm.map((obj, idx) => {
			if (obj.id == customObj.id) {
				index = idx;
			}
		});
		return index;
	}

	getTotalUpCellQuantity = () => {
		let quantityTotal = 0;

		if (this.state.upCellItems) {
			let inputForm = [...this.state.upCellItems];
			inputForm.map((obj) => {
				quantityTotal = quantityTotal + obj.quantity;
			});
		}
		return quantityTotal;
	};
	upcellItemFormHandler = (customObj) => {
		let key = "";
		let value = "";
		let inputForm = [...this.state.upCellItems];

		if (customObj.quantity == 0) {
			inputForm.splice(this.findIndex(customObj), 1);
		} else if (this.findIndex(customObj) == -1 && customObj.quantity == 1) {
			inputForm.push(customObj);
		} else {
			inputForm[this.findIndex(customObj)] = customObj;
		}

		this.setState({ upCellItems: inputForm }, () => {
			this.isAddAble();
		});
	};
	isIn(customObj) {
		let inputForm = [...this.state.customAnswers];
		var pos = inputForm.findIndex(
			(obj) => customObj.owner == obj.owner && customObj.id == obj.id
		);
		if (pos == -1) {
			return false;
		} else {
			return true;
		}
	}
	getClassStatusName = (id) => {
		var status = NOT_CHECKED;
		if (this.state.addStatus == false) {
			status =
				this.state.erorSuggestions.indexOf(id) == -1
					? SUCESS
					: CHECKED_EROR;
		} else {
			status =
				this.state.upCellItems.length > 0 ||
				this.state.customAnswers.length > 0
					? SUCESS
					: NOT_CHECKED;
		}
		return status;
	};
	truncateParagraph = (paragraph, characterLimit) => {
		if (paragraph.length > characterLimit) {
			return paragraph.substring(0, characterLimit) + "...";
		}
		return paragraph;
	};
	render() {
		if (typeof this.props.itemSelected != "undefined") {
			let tabs = this.props.itemSelected.custom_sugestions.map(
				(customObj, idx) => {
					let tabId = "tab" + idx;
					return (
						<Nav.Item>
							<Nav.Link eventKey={tabId}>
								{!customObj.title
									? ""
									: capitalizeName(customObj.title)}
							</Nav.Link>
						</Nav.Item>
					);
				}
			);
			let image = "img/empty_image.jpeg";
			let enable_image_display = this.props.itemSelected.enable_image_display;
			if (enable_image_display) {
				if (this.props.itemSelected.image) {
					image = window.image_path + this.props.itemSelected.image;
				} else if (this.props.default_food_item_image) {
					image = window.image_path + this.props.default_food_item_image;
				}
			}
			// let image = "/img/mall-dedicated-banner.png";
			// image = this.props.itemSelected.image
			//   ? window.image_path  + this.props.itemSelected.image
			//   : image;
			return (
				<Modal
					backdrop={!this.props.isComboItemView}
					show={this.props.show}
					onHide={() => {
						this.setState(
							{
								addStatus: true,
								erorMessages: [],
								quantity: 1,
								item_suggestion: "",
								tabKey: "0",
								tabOpen: true,
								upCellItems: [],
								firstTab: "",
								customAnswers: [],
								variantTabKey: "variant",
								selectedVariant: null,
							},
							() => {
								this.props.onHide();
							}
						);
					}}
					centered
					style={{ borderRadius: 6 }}
					contentClassName="ior-modal-content1"
				>
					{!this.props.isComboItemView ? (
						<Modal.Header closeButton={true}>
							<Modal.Title
								as="h5"
								id="add-address"
								style={{
									display: "flex",
									alignItems: "center",
									fontWeight: "bold",
								}}
							>
								{this.state.selectedVariant && (
									<div
										onClick={() => {
											this.handleVariantChange(null);
										}}
										style={{
											cursor: "pointer",
											padding: 0,
											margin: 0,
											border: "none",
											background: "none",
											outline: "none",
											marginRight: 10,
										}}
									>
										<i
											className="icofont-arrow-left"
											style={{
												fontSize: "23px",
												color: "grey",
												transition: "color 0.3s",
											}}
											onMouseOver={(e) =>
												(e.currentTarget.style.color =
													"black")
											}
											onMouseOut={(e) =>
												(e.currentTarget.style.color =
													"grey")
											}
										></i>
									</div>
								)}
								{/* {" "} */}
								{typeof (
									this.props.itemSelected.title != "undefined"
								) && this.props.itemSelected.title}

								{this.props.itemSelected.is_gluten_free && (
									<Image
										src="/img/gluten_free.png"
										style={{
											width: "20px",
											height: "20px",
											marginLeft: "4px",
										}}
										roundedCircle
									/>
								)}
								{this.props.itemSelected.is_vegan && (
									<Image
										src="/img/vegan.png"
										style={{
											width: "20px",
											height: "20px",
											marginLeft: "4px",
										}}
										roundedCircle
									/>
								)}
								{this.props.itemSelected.is_vegetarian && (
									<Image
										src="/img/vegetarian.png"
										style={{
											width: "20px",
											height: "20px",
											marginLeft: "4px",
										}}
										roundedCircle
									/>
								)}
							</Modal.Title>
						</Modal.Header>
					) : (
						<Modal.Header
							style={{
								alignItems: "center",
								borderBottom: 0,
								// paddingBottom: 1,
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<Modal.Title style={{ textAlign: "left", flex: 1 }}>
								<h5
									style={{
										color: "grey",
										margin: 0,
										display: "flex",
										alignItems: "center",
									}}
								>
									{typeof (
										this.props.itemSelected.title != "undefined"
									) && this.props.itemSelected.title}
									{this.props.itemSelected.is_gluten_free && (
										<Image
											src="/img/gluten_free.png"
											style={{
												width: "20px",
												height: "20px",
												marginLeft: "4px",
											}}
											roundedCircle
										/>
									)}
									{this.props.itemSelected.is_vegan && (
										<Image
											src="/img/vegan.png"
											style={{
												width: "20px",
												height: "20px",
												marginLeft: "4px",
											}}
											roundedCircle
										/>
									)}
									{this.props.itemSelected.is_vegetarian && (
										<Image
											src="/img/vegetarian.png"
											style={{
												width: "20px",
												height: "20px",
												marginLeft: "4px",
											}}
											roundedCircle
										/>
									)}
								</h5>
							</Modal.Title>
							<div
								onClick={() => {
									this.state.selectedVariant
										? this.handleVariantChange(null)
										: this.props.onHide();
								}}
								style={{
									cursor: "pointer",
									padding: 0,
									margin: 0,
									border: "none",
									background: "none",
									outline: "none",
								}}
							>
								<i
									className="icofont-arrow-left"
									style={{
										fontSize: "23px",
										color: "grey",
										transition: "color 0.3s",
									}}
									onMouseOver={(e) =>
										(e.currentTarget.style.color = "black")
									}
									onMouseOut={(e) =>
										(e.currentTarget.style.color = "grey")
									}
								></i>
							</div>
						</Modal.Header>
					)}

					<Modal.Body style={{ overflowY: "auto" }}>
						<Modal.Header
							style={{
								width: "100%",
								borderBottom: 0,
								// ...(this.props.itemSelected.description && {
								// 	paddingBottom: 0,
								// }),
								padding: 0,
								marginBottom: 10,
							}}
						>
							<div
								className="combo-section d-flex "
								style={{
									width: "100%",
								}}
							>
								<Image
									src={image}
									rounded
									width={100}
									height={95}
									className="ior-bundle-image-on-modal"
									style={{ borderRadius: "10px !important" }}
								/>
								<div
									className="ml-3"
									style={{
										justifyContent: "center",
										display: "flex",
										flexDirection: "column",
									}}
								>
									{/* <h5>
									Total Price: $ {this.props.itemSelected.price}
								</h5> */}
									{this.props.itemSelected.description && (
										<p
											style={{
												textAlign: "justify",
												marginBottom: 0,
												fontSize: "14px",
											}}
										>
											{this.props.itemSelected.description}
										</p>
									)}
								</div>
							</div>
						</Modal.Header>
						<div className="d-flex flex-column">
							{this.props.parentVariantDish.is_variant_parent &&
								this.props.parentVariantDish.variants.length > 0 &&
								!this.state.selectedVariant && (
									<VariantTab
										variants={
											this.props.parentVariantDish.variants
										}
										variant_group_title={
											this.props.parentVariantDish
												.variant_group_title
										}
										handleVariantChange={
											this.handleVariantChange
										}
										selectedVariant={this.state.selectedVariant}
									/>
								)}
							{(!this.props.parentVariantDish.is_variant_parent ||
								!this.props.parentVariantDish.variants.length > 0 ||
								this.state.selectedVariant) && (
								<Accordion>
									{this.props.itemSelected.custom_sugestions.map(
										(customObj, idx) => {
											let tabId = "tab" + idx;
											return (
												<Card
													className="mb-3"
													style={{
														borderRadius: "10px",
														boxShadow:
															"0 2px 4px rgba(0, 0, 0, 0.1)",
														transition:
															"transform 0.2s",
														cursor: "pointer",
														height: "auto", // Adjusted to accommodate accordion content
													}}
												>
													<Accordion.Toggle
														// className="ior-combo-card"
														as={Card.Header}
														variant="link"
														eventKey={tabId}
														onClick={() => {
															var tabStatus = !(
																this.state.tabKey ==
																tabId
															);
															var nextTab = tabStatus
																? tabId
																: "0";
															this.setState({
																tabKey: nextTab,
																tabOpen: tabStatus,
															});
														}}
													>
														{!customObj.title
															? ""
															: capitalizeName(
																	customObj.title
															  )}{" "}
														<span
															className={
																SPAN_STATUS[
																	this.getClassStatusName(
																		customObj.id
																	)
																]
															}
														>
															{this.getClassStatusName(
																customObj.id
															) == SUCESS &&
																customObj.is_mandatory ==
																	true && (
																	<Icofont
																		className="icofont-1x text-sucess"
																		icon="check-circled"
																	/>
																)}
															{customObj.is_mandatory ==
															true
																? "Required"
																: "Optional"}
															{this.state.tabKey ===
															tabId ? (
																<Icofont
																	className="icofont-2x text-primary float-right"
																	icon="curved-up"
																/>
															) : (
																<Icofont
																	className="icofont-2x text-primary  float-right"
																	icon="curved-down"
																/>
															)}
														</span>
													</Accordion.Toggle>

													<Accordion.Collapse
														eventKey={"tab" + idx}
													>
														<Card.Body>
															{customObj.tab_type ==
																"1" && (
																<FoodItemSuggestionsTab
																	isAddAble={
																		this
																			.isAddAble
																	}
																	itemId={
																		this.props
																			.itemSelected
																			.id
																	}
																	upcellItemFormHandler={
																		this
																			.upcellItemFormHandler
																	}
																	is_mandatory={
																		customObj.is_mandatory
																	}
																	max_number_of_items_required={
																		customObj.max_number_of_items_required
																	}
																	enable_quantity_limits={
																		customObj.enable_quantity_limits
																	}
																	number_of_items_required={
																		customObj.number_of_items_required
																	}
																	getTotalUpCellQuantity={
																		this
																			.getTotalUpCellQuantity
																	}
																	customObj={
																		customObj
																	}
																	tabId={tabId}
																></FoodItemSuggestionsTab>
															)}
															{customObj.tab_type !=
																"1" && (
																<QuestionSuggestionsTab
																	isAddAble={
																		this
																			.isAddAble
																	}
																	isIn={this.isIn}
																	isInMax={
																		this.isInMax
																	}
																	itemId={
																		this.props
																			.itemSelected
																			.id
																	}
																	answerFormHandler={(
																		event,
																		answerObj,
																		callBack
																	) => {
																		this.answerFormHandler(
																			event,
																			answerObj,
																			callBack
																		);
																	}}
																	customObj={
																		customObj
																	}
																	tabId={tabId}
																></QuestionSuggestionsTab>
															)}
														</Card.Body>
													</Accordion.Collapse>
												</Card>
											);
										}
									)}
								</Accordion>
							)}
						</div>
						{(!this.props.parentVariantDish.is_variant_parent ||
							!this.props.parentVariantDish.variants.length > 0 ||
							this.state.selectedVariant) && (
							<div className="offer-dedicated-body-left mt-2 ">
								<InputGroup className="mb-0">
									<InputGroup.Prepend>
										<InputGroup.Text>
											<Icofont icon="comment" />
										</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control
										style={{
											borderTopRightRadius: 10,
											borderBottomRightRadius: 10,
										}}
										as="textarea"
										name="item_suggestions"
										onChange={this.handleSuggestion}
										placeholder="Add Special Instructions..."
										aria-label="With textarea"
										className="addresslocat"
									/>
								</InputGroup>
							</div>
						)}
						{!this.props.isComboItemView &&
							(!this.props.parentVariantDish.is_variant_parent ||
								!this.props.parentVariantDish.variants.length > 0 ||
								this.state.selectedVariant) && (
								<>
									<Modal.Footer
										style={{
											display: "flex",
											justifyContent: "space-between",
											padding: "5px 15px",
											paddingBottom: "25px",
											backgroundColor: "white",
											borderTop: 0,
											alignItems: "end",
											marginTop: 20,
										}}
									>
										<span className="count-number  d-flex mr-3">
											<Button
												variant="outline-secondary"
												onClick={(e) => {
													e.preventDefault();
													this.setState({
														quantity:
															this.state.quantity != 1
																? this.state
																		.quantity -
																  1
																: 1,
													});
												}}
												className="btn-sm left dec"
											>
												{" "}
												<Icofont icon="minus" />{" "}
											</Button>
											<input
												className="count-number-input"
												type="text"
												value={this.state.quantity}
												readOnly
												style={{
													marginLeft: 5,
													marginRight: 5,
													width: 50,
												}}
											/>
											<Button
												variant="outline-secondary"
												onClick={(e) => {
													e.preventDefault();
													this.setState({
														quantity:
															this.state.quantity + 1,
													});
												}}
												className="btn-sm right inc"
											>
												{" "}
												<Icofont icon="icofont-plus" />{" "}
											</Button>
										</span>
										<span className="">
											$
											<b>
												{Number(
													this.state.quantity *
														this.getTotalPrice(
															this.props.itemSelected
														)
												).toFixed(2)}
											</b>
										</span>
									</Modal.Footer>
								</>
							)}
						{/* <Modal.Footer
							style={{
								display: "flex",
								justifyContent: "center",
								padding: "5px 15px",
								// backgroundColor: "rgba(0, 151, 136, 0.1)",
								borderTop: 0,
							}}
						>
							

							<Button
								variant={
									this.state.addStatus ? "primary" : "danger"
								}
								style={{
									backgroundColor: "white",
									border: "none",
									borderRadius: "20px",
									padding: "8px 16px",
									color: "#fff",
									fontWeight: "bold",
									boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
								}}
								onClick={(e) => {
									e.preventDefault();
									if (this.isAddAble() == true) {
										this.setCustomaize();
									}
								}}
							>
								{this.state.addStatus
									? this.props.isComboItemView
										? "Add"
										: "Add To Cart"
									: "Selection Required"}
							</Button>
						</Modal.Footer> */}
					</Modal.Body>
					<Modal.Footer
						style={{
							display: "flex",
							justifyContent: "center",
							padding: "5px 15px",
							// backgroundColor: "rgba(0, 151, 136, 0.1)",
							borderTop: 0,
						}}
					>
						<Button
							disabled={
								this.props.parentVariantDish.is_variant_parent &&
								this.props.parentVariantDish.variants.length > 0 &&
								!this.state.selectedVariant
							}
							variant={this.state.addStatus ? "primary" : "danger"}
							style={{
								backgroundColor: "white",
								border: "none",
								borderRadius: "20px",
								padding: "8px 16px",
								color: "#fff",
								fontWeight: "bold",
								boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
								width: "100%",
							}}
							onClick={(e) => {
								e.preventDefault();
								if (this.isAddAble() == true) {
									this.setCustomaize();
								}
							}}
						>
							{this.state.addStatus
								? this.props.isComboItemView
									? "Add"
									: "Add To Cart"
								: "Selection Required"}
						</Button>
					</Modal.Footer>
				</Modal>
			);
		} else {
			return "";
		}
	}
}

class FoodItem extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			quantity: 0,
			status: this.props.status,
		};
	}

	render() {
		return (
			<Form.Group className="col-md-12">
				{this.state.quantity === 0 && (
					<span className="float-right mr-5 mb-4">
						<Button
							variant="outline-primary"
							size="sm"
							style={{ position: "absolute" }}
							disabled={this.props.tabStatus}
							onClick={(event) => {
								this.setState({ status: true, quantity: 1 }, () => {
									this.props.incrementTotal();
									this.props.upcellItemFormHandler({
										...this.props.foodItemObj,
										quantity: 1,
									});
								});
							}}
							// className="btn btn-primary btn-sm position-absolute"
						>
							ADD
						</Button>
					</span>
				)}

				{this.state.quantity > 0 && (
					<span className="count-number float-right">
						<Button
							variant="outline-secondary"
							onClick={(event) => {
								event.preventDefault();
								let q = this.state.quantity - 1;
								this.setState({ status: true, quantity: q }, () => {
									this.props.decrementTotal();
									this.props.upcellItemFormHandler({
										...this.props.foodItemObj,
										quantity: q,
									});
								});
							}}
							className="btn-sm left dec"
						>
							{" "}
							<Icofont icon="minus" />{" "}
						</Button>
						<input
							className="count-number-input"
							type="text"
							value={this.state.quantity}
							readOnly
						/>
						<Button
							variant="outline-secondary"
							disabled={this.props.tabStatus}
							onClick={(event) => {
								event.preventDefault();
								let q = this.state.quantity + 1;
								this.setState({ status: true, quantity: q }, () => {
									this.props.incrementTotal();
									this.props.upcellItemFormHandler({
										...this.props.foodItemObj,
										quantity: q,
									});
								});
							}}
							className="btn-sm right inc"
						>
							{" "}
							<Icofont icon="icofont-plus" />{" "}
						</Button>
					</span>
				)}
			</Form.Group>
		);
	}
}

class FoodItemSuggestionsTab extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = { tabTotal: 0, tabStatus: false };
		this.incrementTotal = this.incrementTotal.bind(this);
		this.decrementTotal = this.decrementTotal.bind(this);
	}

	incrementTotal = () => {
		this.setState(
			{
				tabTotal: this.state.tabTotal + 1,
			},
			() => {
				console.log("TabTotal", this.state.tabTotal);
				console.log("Required", this.props.number_of_items_required);

				this.setState({
					tabStatus:
						this.state.tabTotal ==
						this.props.max_number_of_items_required,
				});
			}
		);
	};

	decrementTotal = () => {
		this.setState(
			{
				tabTotal: this.state.tabTotal - 1,
			},
			() => {
				console.log("TabTotal", this.state.tabTotal);
				console.log("Required", this.props.number_of_items_required);

				this.setState({
					tabStatus:
						this.state.tabTotal ==
						this.props.max_number_of_items_required,
				});
			}
		);
	};
	render() {
		console.log("Tab Id", this.props.tabId);

		return (
			<Tab.Pane eventKey={this.props.tabId}>
				<Row>
					{/*((this.props.customObj.enable_quantity_limits==true)&&
        (Number(this.props.customObj.number_of_items_required)!=Number(this.props.customObj.max_number_of_items_required)))
        &&
          <h6 className="mb-4 mt-1 col-md-12">
           Select  {" "}
            <small className="h6 text-black-50">
              {this.props.customObj.number_of_items_required+" to "+this.props.customObj.max_number_of_items_required +"items"} 
            </small>
          </h6>*/}

					<h6 className="mb-4 mt-1 col-md-12">
						{this.props.customObj.question_title}
					</h6>
					<Col md={12}>
						{this.props.customObj.upsell_items.map(
							(foodItemObj, idx) => {
								return (
									<div className="bg-white rounded border shadow-sm mb-0">
										<div
											className={
												"p-2 border-bottom gold-members"
											}
										>
											<Row>
												<Col
													md={6}
													style={{ width: "50%" }}
												>
													<Media>
														<Media.Body>
															<h7 className="mb-1">
																{
																	foodItemObj.item_name
																}{" "}
															</h7>
															{!foodItemObj.free_available && (
																<p className="text-gray mb-0">
																	$
																	{
																		foodItemObj.item_price
																	}
																</p>
															)}
															{foodItemObj.free_available && (
																<p className="text-gray mb-0">
																	${"0.00"}
																</p>
															)}
														</Media.Body>
													</Media>
												</Col>
												<Col
													md={6}
													style={{ width: "50%" }}
												>
													<Row>
														<Col md={12}>
															<FoodItem
																tabStatus={
																	this.props
																		.enable_quantity_limits
																		? this.state
																				.tabStatus
																		: false
																}
																getTotalUpCellQuantity={
																	this.props
																		.getTotalUpCellQuantity
																}
																incrementTotal={
																	this
																		.incrementTotal
																}
																decrementTotal={
																	this
																		.decrementTotal
																}
																foodItemObj={
																	foodItemObj
																}
																upcellItemFormHandler={
																	this.props
																		.upcellItemFormHandler
																}
															></FoodItem>
														</Col>
													</Row>
												</Col>
											</Row>
										</div>
									</div>
								);
							}
						)}
					</Col>
				</Row>
			</Tab.Pane>
		);
	}
}

class QuestionItem extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			status: this.props.status,
			quantity: this.props.quantity,
		};
	}

	render() {
		return (
			<Form.Group className="col-md-12">
				<Form.Check
					custom
					type={this.props.taip}
					id={this.props.answerObj.id}
					onChange={(event) => {
						this.setState({ status: event.target.checked });
						this.props.answerFormHandler(event);
					}}
					value={this.props.answerObj.id}
					checked={this.props.isIn(this.props.answerObj)}
					disabled={this.props.tabStatus}
					label={
						capitalizeName(this.props.answerObj.answer_title) +
						" $" +
						this.props.answerObj.item_price
					}
				/>
			</Form.Group>
		);
	}
}

class QuestionSuggestionsTab extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.choices = ["radio", "radio", "checkbox"];
		this.state = { tabStatus: false };
	}
	render() {
		return (
			<Tab.Pane eventKey={this.props.tabId}>
				<Col md={12}>
					<Row>
						{this.props.customObj.custom_questions.map(
							(questionItemObj, idx) => {
								let choiceTaip =
									this.choices[questionItemObj.question_type];
								return (
									<div className="p-2">
										<Row>
											<Col md={12}>
												<h6 className="mb-4 mt-3 col-md-12">
													{capitalizeName(
														questionItemObj.title
													)}{" "}
												</h6>
												{questionItemObj.answers.map(
													(answerObj) => {
														return (
															<QuestionItem
																answerObj={
																	answerObj
																}
																isIn={
																	this.props.isIn
																}
																taip={choiceTaip}
																tabStatus={
																	!this.props.isIn(
																		answerObj
																	) == true
																		? this.state
																				.tabStatus
																		: false
																}
																answerFormHandler={(
																	event
																) => {
																	console.log(
																		"event",
																		event
																	);
																	this.props.answerFormHandler(
																		event,
																		answerObj,
																		() => {
																			this.setState(
																				{
																					tabStatus:
																						!this.props.isInMax(
																							this
																								.props
																								.customObj
																						),
																				}
																			);
																		}
																	);
																}}
															></QuestionItem>
														);
													}
												)}
											</Col>
										</Row>
									</div>
								);
							}
						)}
					</Row>
				</Col>
			</Tab.Pane>
		);
	}
}

export default ViewMenuDiscriptionModal;
