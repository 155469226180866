import React from "react";
import {
	Form,
	Row,
	Col,
	InputGroup,
	Modal,
	FormGroup,
	Label,
	ButtonToolbar,
	Button,
	ToggleButton,
	ToggleButtonGroup,
	Image,
} from "react-bootstrap";
import Icofont from "react-icofont";
import SlotPicker from "./SlotPicker";
import Moment from "moment";
import {
	getCurrentDate,
	getTodayString,
	toClientTime,
	findDateFromDay,
	getDateAfter,
	getDay,
	isInBetween,
	isTimeBetween,
} from "../../services/DataFormatHandler";
import TimePicker from "react-bootstrap-time-picker";
import SearchLocationInput from "../location/SearchLocationInput";
import SimpleReactValidator from "simple-react-validator";
import { css } from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getComponent } from "../../helpers/helpers";

function getFormattedDateTime(dateTime) {
	dateTime = Moment(dateTime);

	const today = Moment().startOf("day");
	const tomorrow = Moment().add(1, "days").startOf("day");

	// message =
	// 	orderMode === C.orderModes.varIdMap.takeOut
	// 		? "Takeout order for "
	// 		: "Delivery order for ";
	if (dateTime.isSame(today, "d")) {
		return "today";
	} else if (dateTime.isSame(tomorrow, "d")) {
		return "tomorrow";
	} else {
		return dateTime.format("Do dddd").toLowerCase();
	}
}

function getOrderTypeRelatedCustomerMessage(params) {
	let { dateTime, time, orderMode, orderType } = params;
	let message = "";
	dateTime = Moment(dateTime);

	const today = Moment().startOf("day");
	const tomorrow = Moment().add(1, "days").startOf("day");

	message = orderMode == 1 ? "takeout order for " : "delivery order for ";
	if (dateTime.isSame(today, "d")) {
		message = message + "today";
	} else if (dateTime.isSame(tomorrow, "d")) {
		message = message + "tomorrow";
	} else {
		message = message + dateTime.format("Do dddd").toLowerCase();
	}

	message = `You are scheduling this ${message} ${time}`;

	return message;
}

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
	<input
		style={{ cursor: "pointer" }}
		onClick={onClick}
		value={value}
		ref={ref}
		readOnly
		className="custom-date-picker"
	/>
));

class DeliveryModal extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.today = getCurrentDate("YYYY-MM-DD");
		this.maxDate = getDateAfter(4, "YYYY-MM-DD");
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.lastSelectedDelmode = null;
		this.lastSelectedOrderType = null;
		this.state = {
			times: [],
			minDate: this.today,
			maxDate: this.maxDate,
			showFuture: false,
			timeSlots: [],
			times: [],
			notAvailable: true,
			availableTimes: [],
			showError: false,
			apartment: "",
		};

		this.setAddress = this.setAddress.bind(this);
		this.onOrderModeClick = this.onOrderModeClick.bind(this);
	}

	isTimeAvailable = (slotObj, slots, date = Moment().format("YYYY-MM-DD")) => {
		let status = false;
		let minuteDifference = window.FutureOrderDeliveryTimeDifference || 60;
		if (this.props.del_mode == 1) {
			minuteDifference = window.FutureOrderTakeoutTimeDifference || 0;
		}
		for (let i = 0; i < slots.length; i++) {
			let copentime = toClientTime(
				this.props.profile.time_zone,
				findDateFromDay(slots[i]["day"]) + " " + slots[i]["opening_time"]
			);
			let cclosetime = toClientTime(
				this.props.profile.time_zone,
				findDateFromDay(slots[i]["day"]) + " " + slots[i]["closing_time"]
			);
			let currenTime = Moment(
				date + " " + slotObj.start_time,
				"YYYY-MM-DD h:mm A"
			);
			let cStatus =
				currenTime.isSameOrAfter(
					Moment().add(minuteDifference, "minutes")
				) &&
				((currenTime.isSameOrAfter(copentime) &&
					currenTime.isBefore(cclosetime)) ||
					(currenTime.isSameOrBefore(cclosetime) &&
						currenTime.isSameOrAfter(copentime)));

			/*
			status=status || (isTimeBetween(slots[i].opening_time,
				slots[i].closing_time,
				slotObj.start_time))
				*/
			status = status || cStatus;
		}
		return status;
	};
	componentDidMount() {
		this.addTimeSplits();
	}

	componentDidUpdate() {
		if (!(this.state.del in [0, 1])) {
			if (
				this.props.profile.disable_takeout &&
				this.props.profile.disable_delivery === false
			) {
				this.onOrderModeClick("0");
			} else if (
				this.props.profile.disable_takeout === false &&
				this.props.profile.disable_delivery
			) {
				this.onOrderModeClick("1");
			}
		}
	}

	addTimeSplits = (timeSlotObj) => {
		let id = 1;
		let time_split = window.FutureOrderTimeSplit || 15;
		let slotTime = Moment("0:0:0", "HH:mm:ss");
		let endTime = Moment("24:0:0", "HH:mm:ss");
		while (slotTime < endTime) {
			let prevTime = slotTime.format("hh:mm A");
			slotTime = slotTime.add(time_split, "minutes");
			let nextTime = slotTime.format("hh:mm A");
			this.state.times.push({
				id: 1,
				start_time: prevTime,
				end_time: nextTime,
			});
		}
	};

	setDayTimes(set = true) {
		let date = Moment(getCurrentDate("YYYY-MM-DD")).format("YYYY-MM-DD");
		let status = true;
		let availableTimes = [];
		let timeSlots = [];
		const getAvaialbelTimes = (date) => {
			var day = getDay(date);
			timeSlots = this.getSlots(day);

			var availableTimes = [...this.state.times].filter((slotObj) => {
				return this.isTimeAvailable(slotObj, timeSlots, date);
			});

			return availableTimes;
		};

		for (let i = 1; i < 6; i++) {
			availableTimes = getAvaialbelTimes(date);
			if (availableTimes.length > 0) {
				break;
			}
			date = Moment(getCurrentDate("YYYY-MM-DD"))
				.add(i, "days")
				.format("YYYY-MM-DD");
		}
		if (availableTimes.length == 0) {
			status = false;
		}
		if (!set) {
			return { date, availableTimes };
		}
		this.setState(
			{
				timeSlots: timeSlots,
				availableTimes: availableTimes,
				notAvailable: status,
				emptyItem: false,
			},
			() => {
				this.props.disableEmptyItem();

				this.props.setShoppingDate(date);
				if (this.state.availableTimes.length > 0) {
					this.props.setShoppingTime(
						this.state.availableTimes[0].start_time
					);
				}
			}
		);
	}

	getSlots(day) {
		let timeSlots = [];
		let availableDaysForTakeout = this.props.profile.availableDaysForTakeout;
		let availableDaysForDelivery = this.props.profile.availableDaysForDelivery;
		if (
			this.props.profile.enable_separate_delivery_schedule &&
			this.props.del_mode == 0
		) {
			if (availableDaysForDelivery && availableDaysForDelivery[day]) {
				timeSlots = availableDaysForDelivery[day];
			}
		} else {
			if (availableDaysForTakeout && availableDaysForTakeout[day]) {
				timeSlots = availableDaysForTakeout[day];
			}
		}
		return timeSlots;

		// if (this.props.profile.available_days) {
		// 	let timeSlots = (this.props.profile.available_days[day]) ? this.props.profile.available_days[day] : []

		// 	return timeSlots;
		// }
		// return [];
	}
	setDelMode = () => {};
	setAddress(addressObject) {
		if (addressObject.formatted_address) {
			this.setState(
				{ address: addressObject },
				this.props.setShoppingAddress(addressObject)
			);
		}
		let apartment = getComponent(
			addressObject.address_components,
			"subpremise"
		);
		if (apartment) {
			this.setState({ apartment: apartment });
		}
	}
	validateModel = (e) => {
		console.log("va;idation");
		this.props.setApartment(this.state.apartment);
		let modeValid = this.validator.fieldValid("delivery_mode") == true;
		console.log(modeValid);
		if (this.state.del == 0) {
			console.log(this.state.del);
			console.log(this.validator.fieldValid("address") == true);
			console.log(this.props.addressEror == false);

			modeValid =
				modeValid &&
				this.validator.fieldValid("address") == true &&
				this.props.addressEror == false;
			console.log(modeValid);
		}
		if (this.state.showFuture == true) {
			modeValid =
				modeValid &&
				this.validator.fieldValid("shopping_date") == true &&
				this.validator.fieldValid("time") == true;
		}
		if (modeValid == false) {
			this.validator.showMessages();
			return modeValid;
		} else {
			this.validator.hideMessages();
			return modeValid;
		}
	};
	handleDate(date) {
		this.props.setShoppingDate(date.target.value);
	}
	setNotAvailable = (value) => {
		this.setState({ notAvailable: value });
	};

	onOrderModeClick = (v) => {
		this.lastSelectedDelmode = v;
		this.setState({
			del: v,
			showFuture: false,
			timeSlots: [],
			notAvailable: true,
			availableTimes: [],
			showError: false,
			address: null,
		});
		this.props.changeDeliveryMode(v);
	};

	checkError = (params) => {
		const {
			isFutureOrder,
			isTimeSlotsAvailable,
			isEmpty,
			delMode,
			lastSelectedOrderType,
		} = params;
		let message = "";

		if (isFutureOrder && !isTimeSlotsAvailable) {
			return "No future slots available";
		}

		if (isEmpty) {
			if (lastSelectedOrderType === "future") {
				return delMode == 1
					? "Sorry ! No slots are available for takeout on selected day, please choose another time."
					: "Sorry ! No slots are available for delivery on selected day, please choose another time.";
			}
			return delMode == 1
				? "Sorry ! No slots are available for takeout, please use “Order for Later” for pre-order"
				: "Sorry ! No slots are available for Delivery, please use “Order for Later” for pre-order";
		}

		return message;
	};
	render() {
		// console.log(this.props.profile);
		return (
			<Modal
				onShow={() => this.setState({ showError: true })}
				className={"zx"}
				show={this.props.show}
				onHide={this.props.onHide}
				centered
				contentClassName="ior-modal-content2"
				backdropClassName="ior-modal-backdrop2"
			>
				{/* <Modal.Header closeButton={false}>
					
				</Modal.Header> */}
				<Modal.Title
					as="h5"
					id="add-address"
					style={{
						width: "100%",
						textAlign: "center",
						fontSize: 15,
						fontWeight: "600",
						marginTop: 5,
					}}
				>
					Select Order Mode
				</Modal.Title>

				<Modal.Body>
					<Modal.Title
						as="h6"
						id="add-address"
						style={{
							width: "100%",
							textAlign: "center",
							fontSize: 12,
							fontWeight: "600",
							color: "grey",
						}}
					>
						{this.props.profile.disable_delivery &&
							"*Currently delivery is not available"}
						{this.props.profile.disable_takeout &&
							"*Currently takeout is not available"}
					</Modal.Title>
					<Form>
						<div className="form-row">
							<Form.Group className="col-md-12 ">
								<Row
									className="text-center  radio-toolbar"
									style={{ flexWrap: "nowrap" }}
								>
									<Col md={3}></Col>
									{/* {this.props.profile.disable_takeout ==
										false && ( */}
									<Col
										md={
											this.props.profile.disable_delivery
												? 3
												: 3
										}
										sm={
											this.props.profile.disable_delivery
												? 4
												: 4
										}
										className=" mt-1 mb-0"
									>
										<input
											disabled={
												// this.props.profile
												// 	.disable_delivery
												this.props.profile.disable_takeout
											}
											type="radio"
											value={1}
											name="delivery_mode"
											onClick={(v) => {
												this.onOrderModeClick("1");
											}}
											id="take_radio"
											checked={this.state.del == 1}
										/>
										<label
											for="take_radio"
											style={{
												...(this.props.profile
													.disable_takeout
													? {
															opacity: 0.5,
															background: "inherit",
													  }
													: {}),
											}}
										>
											<Image fluid src="img/take-away.png" />
											<br></br>Takeout
										</label>
									</Col>
									{/* )} */}
									{/* {this.props.profile.disable_delivery ==
										false && ( */}
									<Col
										md={
											this.props.profile.disable_takeout
												? 3
												: 3
										}
										sm={
											this.props.profile.disable_takeout
												? 4
												: 4
										}
										className=" mt-1 mb-0"
									>
										<input
											disabled={
												// this.props.profile.disable_takeout
												this.props.profile.disable_delivery
											}
											type="radio"
											value={0}
											name="delivery_mode"
											onClick={(v) => {
												this.onOrderModeClick("0");
											}}
											id="del_radio"
											checked={this.state.del == 0}
										/>
										<label
											for="del_radio"
											style={{
												...(this.props.profile
													.disable_delivery
													? {
															opacity: 0.5,
															background: "inherit",
													  }
													: {}),
											}}
										>
											<Image
												fluid
												src="img/delivery-man.png"
											/>
											<br></br>Delivery
										</label>
									</Col>
									{/*)}*/}
									<Col md={3}></Col>
								</Row>
								{((!this.props.currentlyTakeoutAvailable &&
									this.state.del == 1) ||
									(!this.props.currentlyDeliveryAvailable &&
										this.state.del == 0)) &&
									!this.state.showFuture && (
										<>
											<div>
												<Row>
													<Col
														md={12}
														className="d-flex w-100 text-center justify-content-center"
													>
														<div
															style={{
																alignSelf: "center",
																width: "100%",
																justifyContent:
																	"center",
																padding: 5,
																marginTop: 10,
																marginBottom: 10,
																borderRadius: 5,
																// backgroundColor:
																// 	"#e57373",
															}}
														>
															<>
																{this.state.del ==
																1 ? (
																	<div
																		style={{
																			display:
																				"flex",
																			flexDirection:
																				"column",
																		}}
																	>
																		<span
																			style={{
																				color: "red",
																			}}
																		>
																			{
																				"We are currently closed for takeout."
																			}
																		</span>{" "}
																		{`We will open ${getFormattedDateTime(
																			this.setDayTimes(
																				false
																			)
																				?.date ||
																				""
																		)} at ${
																			this.setDayTimes(
																				false
																			)
																				?.availableTimes?.[0]
																				?.start_time ||
																			""
																		}. You can Pre-Order for later.`}
																	</div>
																) : (
																	<div
																		style={{
																			display:
																				"flex",
																			flexDirection:
																				"column",
																		}}
																	>
																		<span
																			style={{
																				color: "red",
																			}}
																		>
																			{
																				"We are currently closed for delivery."
																			}
																		</span>{" "}
																		{`We open ${getFormattedDateTime(
																			this.setDayTimes(
																				false
																			)
																				?.date ||
																				""
																		)} at ${
																			this.setDayTimes(
																				false
																			)
																				?.availableTimes?.[0]
																				?.start_time ||
																			""
																		}. You can pre-order for later.`}
																	</div>
																)}
															</>
														</div>
													</Col>
												</Row>
											</div>
										</>
									)}
								<Row>
									<span
										style={{
											textAlign: "center",
											width: "100%",
											marginBottom: 10,
										}}
									>
										{this.validator.message(
											"delivery_mode",
											this.state.del,
											"required",
											{
												className: "text-danger",
												messages: {
													required:
														"Choose Takeout or Delivery to Continue",
												},
											}
										)}
									</span>
								</Row>
								{this.state.del == 0 && (
									<>
										<span>
											<Form.Label>
												<b>Delivery Address</b>
											</Form.Label>
											<Row>
												<Col md={12}>
													<SearchLocationInput
														country={
															this.props.profile
																.country_config.map
																? this.props.profile
																		.country_config
																		.map
																: "ca"
														}
														addressDisabled={true}
														setBack={(a) => {
															this.setState({
																showFuture: false,
															});
															this.setAddress(a);
														}}
													/>
													{this.props.addressEror ==
														true && (
														<div>
															<Row>
																<Col
																	md={12}
																	className="d-flex w-100 text-center justify-content-center"
																>
																	<div
																		style={{
																			// minHeight: 50,
																			justifyContent:
																				"center",
																			padding: 5,
																			marginTop: 10,
																			marginBottom: 10,
																			borderRadius: 5,
																			backgroundColor:
																				"#e57373",
																		}}
																	>
																		Outside
																		Delivery
																		area, please
																		choose
																		Takeout
																	</div>
																</Col>
															</Row>
														</div>
													)}
												</Col>
											</Row>
											<Row>
												<span
													style={{
														textAlign: "center",
														width: "100%",
														marginTop: 5,
														marginBottom: 10,
													}}
												>
													{this.validator.message(
														"address",
														this.state.address,
														"required",
														{ className: "text-danger" }
													)}
												</span>
											</Row>
										</span>
										<span>
											<Form.Label>
												<b>Suite/ Apt Number </b>(Optional)
											</Form.Label>
											<Row>
												<Col md={12}>
													<Form.Group>
														<InputGroup>
															<Form.Control
																type="text"
																name="apartment"
																className="addresslocat"
																placeholder="Suite/ Apt Number"
																value={
																	this.state
																		.apartment
																}
																onChange={(e) => {
																	this.setState({
																		apartment:
																			e.target
																				.value,
																	});
																}}
															/>
														</InputGroup>
													</Form.Group>
												</Col>
											</Row>
										</span>
									</>
								)}

								<Row>
									<Col md={6} sm={6} className="mb-2">
										<Button
											disabled={
												(!this.props
													.currentlyTakeoutAvailable &&
													this.state.del == 1) ||
												((!this.props
													.currentlyDeliveryAvailable ||
													this.props.addressEror ==
														true ||
													!this.state.address) &&
													this.state.del == 0)
											}
											style={{ borderRadius: 5 }}
											type="button"
											onClick={(e) => {
												if (this.validateModel() == true) {
													this.lastSelectedOrderType =
														"current";
													this.setState(
														{
															minDate: this.today,
															maxDate: this.maxDate,
															showFuture: false,
															del: null,

															address: null,
														},
														() => {
															console.log(this.state);
															this.props.setCurrentDeliveryMode(
																e
															);
														}
													);
												}
											}}
											className="d-flex w-100 text-center justify-content-center "
										>
											Order Now
										</Button>
									</Col>
									<br></br>
									<Col md={6} sm={6}>
										<Button
											disabled={
												this.state.del == 0 &&
												(this.props.addressEror == true ||
													!this.state.address)
											}
											style={{ borderRadius: 5 }}
											type="button"
											onClick={(e) => {
												if (this.validateModel() == true) {
													if (
														this.state.showFuture ==
														false
													) {
														this.lastSelectedOrderType =
															"future";
														this.setState(
															{ showFuture: true },
															() => {
																this.setDayTimes();
															}
														);
														//this.props.setShoppingTime(500);
													}
												}
											}}
											className="d-flex w-100 text-center justify-content-center ordercolor"
										>
											Order For Later
										</Button>
									</Col>
								</Row>
								{this.state.showFuture == true && (
									<Row>
										<Col md={6}>
											<Form.Label style={{ marginTop: 15 }}>
												Choose Date
											</Form.Label>
											<DatePicker
												className="custom-date-picker"
												selected={Moment(
													this.props.shopping_date
												).toDate()}
												onChange={(date) => {
													let date_value =
														Moment(date).format(
															"YYYY-MM-DD"
														);
													var day = getDay(date_value);
													var timeSlots =
														this.getSlots(day);
													let status = true;

													var availableTimes = [
														...this.state.times,
													].filter((slotObj) => {
														return this.isTimeAvailable(
															slotObj,
															timeSlots,
															date_value
														);
													});
													if (
														availableTimes.length == 0
													) {
														status = false;
													}
													this.setState(
														{
															timeSlots: timeSlots,
															availableTimes:
																availableTimes,
															notAvailable: status,
															emptyItem: false,
														},
														() => {
															this.props.disableEmptyItem();

															this.props.setShoppingDate(
																date_value
															);
															if (
																this.state
																	.availableTimes
																	.length > 0
															) {
																this.props.setShoppingTime(
																	this.state
																		.availableTimes[0]
																		.start_time
																);
															}
														}
													);
												}}
												includeDates={[
													new Date(),
													new Date().setDate(
														new Date().getDate() + 1
													),
													new Date().setDate(
														new Date().getDate() + 2
													),
													new Date().setDate(
														new Date().getDate() + 3
													),
													new Date().setDate(
														new Date().getDate() + 4
													),
												]}
												placeholderText="Please select your  date"
												customInput={<CustomInput />}
											/>
										</Col>
										<Col md={6}>
											<Form.Group>
												<Form.Label
													style={{ marginTop: 15 }}
												>
													Choose Time
												</Form.Label>

												<SlotPicker
													value={this.props.time}
													availableTimes={
														this.state.availableTimes
													}
													onChange={(event) => {
														this.props.disableEmptyItem();
														let time =
															event.target.value;
														this.setState(
															{ emptyItem: false },
															() => {
																this.props.setShoppingTime(
																	time
																);
															}
														);
													}}
												></SlotPicker>
											</Form.Group>
										</Col>
										{this.state.showFuture &&
											this.state.notAvailable && (
												<div
													style={{
														// display: "flex",
														// flexDirection: "column",
														width: "100%",
														textAlign: "center",
														marginBottom: 10,
													}}
												>
													<span
														style={{
															color: "black",
															fontWeight: 600,
															alignItems: "center",
															textAlign: "center",
														}}
													>
														{getOrderTypeRelatedCustomerMessage(
															{
																dateTime:
																	this.props
																		.shopping_date,
																time: this.props
																	.time,
																orderMode:
																	this.state.del,
																// orderType: this.lastSelectedOrderType,
															}
														)}
													</span>
												</div>
											)}

										{/* <Col md={2} className="mt-4"> */}
										<Button
											disabled={!this.state.notAvailable}
											style={{
												borderRadius: 100,
												justifyContent: "center !important",
												marginLeft: "25%",
												marginRight: "25%",
											}}
											type="button"
											onClick={(e) => {
												if (
													this.state.notAvailable ==
														true &&
													this.validateModel() == true
												) {
													this.state = {};
													this.setState(
														{
															minDate: this.today,
															maxDate: this.maxDate,
															showFuture: false,
															del: null,
															address: null,
														},
														() => {
															console.log(this.state);
															this.props.setDeleveryMode(
																e
															);
														}
													);
												}
											}}
											className="d-flex w-100 text-center justify-content-center"
										>
											Proceed
										</Button>
										{/* </Col> */}
										<span>
											{this.validator.message(
												"shopping_date",
												this.props.shopping_date,
												"required",
												{ className: "text-danger" }
											)}
										</span>

										<span>
											{this.validator.message(
												"time",
												this.props.time,
												"required",
												{ className: "text-danger" }
											)}
										</span>
									</Row>
								)}
								{/* {this.state.notAvailable == false && (
									<Row>
										<Col
											md={12}
											className="d-flex w-100 text-center justify-content-center"
										>
											<p className="text-danger">
												{" "}
												<Icofont icon="warning" />
											</p>
											<h6 className="text-danger">
												{" "}
												Sorry ! No slots are available on
												selected day
											</h6>
										</Col>
									</Row>
								)} */}
								{this.checkError({
									isFutureOrder: this.state.showFuture,
									isTimeSlotsAvailable: this.state.notAvailable,
									isEmpty: Boolean(
										this.props.emptyItem &&
											this.props.categories &&
											this.state.showError &&
											this.lastSelectedDelmode
									),
									delMode: this.lastSelectedDelmode,
									lastSelectedOrderType:
										this.lastSelectedOrderType,
								}) && (
									<div>
										<Row>
											<Col
												md={12}
												className="d-flex w-100 text-center justify-content-center"
											>
												<div
													style={{
														// minHeight: 50,
														justifyContent: "center",
														padding: 5,
														marginTop: 20,
														borderRadius: 5,
														backgroundColor: "#e57373",
													}}
												>
													{this.checkError({
														isFutureOrder:
															this.state.showFuture,
														isTimeSlotsAvailable:
															this.state.notAvailable,
														isEmpty: Boolean(
															this.props.emptyItem &&
																this.props
																	.categories &&
																this.state
																	.showError &&
																this
																	.lastSelectedDelmode
														),
														delMode:
															this
																.lastSelectedDelmode,
														lastSelectedOrderType:
															this
																.lastSelectedOrderType,
													})}
												</div>
											</Col>
										</Row>
									</div>
								)}
								{/* {this.props.emptyItem && this.props.categories && (
									<div>
										<Row>
											<Col
												md={12}
												className="d-flex w-100 text-center justify-content-center"
											>
												<p className="text-danger">
													{" "}
													<Icofont icon="warning" />
												</p>
												<h6 className="text-danger">
													{" "}
													Closed, please use “Order for
													Later” for pre-order
												</h6>
												<div
													style={{
														minHeight: 50,
														justifyContent: "center",
														padding: 10,
														marginTop: 20,
														borderRadius: 5,
														backgroundColor: "#e57373",
													}}
												>
													"Sorry ! No slots are available
													for takeout, please use “Order
													for Later” for pre-order"
												</div>
											</Col>
										</Row>
										<Row>
											<Col
												md={12}
												className="d-flex w-100 text-center justify-content-center"
											>
												<p>
													{" "}
													Sorry ! No items are available
													with previous selection.
												</p>
												<p>Please choose another time.</p>
											</Col>
										</Row>
									</div>
								)} */}
							</Form.Group>
						</div>
					</Form>
				</Modal.Body>

				{/*}	<Modal.Footer>
					<Button type='button' variant="primary"
					onClick={this.props.setDeleveryMode}
					className='d-flex w-100 text-center justify-content-center'>SUBMIT</Button>
								</Modal.Footer>*/}
			</Modal>
		);
	}
}
export default DeliveryModal;
