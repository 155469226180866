import React from "react";
import {
	Modal,
	Button,
	Form,
	Image,
	ProgressBar,
	ListGroup,
	Accordion,
	Card,
} from "react-bootstrap";
import Icofont from "react-icofont";
import ViewMenuDiscriptionModel from "./ViewMenuDiscriptionModal";
import DataService from "../../services/DataService";
import VariantTab from "../common/VariantTab";

function convertDictItemsToList(dict) {
	let list = [];
	let item = null;

	for (let key in dict) {
		item = dict[key];
		list.push(item);
	}

	return list;
}

class BundleItemSelectionModal extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.dataHandler = new DataService("restaurants");
		this.state = {
			completedComboItems: { list: [], dict: {} },
			currentSelectedComboItem: null,
			currentSelectedChoiceItem: null,
			comboTotalPrice: this.props.itemSelected.price,
			parentPrice: this.props.itemSelected.price,
			comboSubItems: [],
			parentVariantDish: null,
			selectedVariant: null,
		};

		this.addToCompletedComboItems = this.addToCompletedComboItems.bind(this);
		this.setCurrentSelectedComboItem =
			this.setCurrentSelectedComboItem.bind(this);
		this.setCurrentSelectedChoiceItem =
			this.setCurrentSelectedChoiceItem.bind(this);
		this.addComboToCart = this.addComboToCart.bind(this);
		this.updateComboTotalPrice = this.updateComboTotalPrice.bind(this);
		this.handleVariantSelection = this.handleVariantSelection.bind(this);
	}
	handleVariantChange = (variant) => {
		this.setState({
			selectedVariant: variant,
			completedComboItems: { list: [], dict: {} },
			currentSelectedComboItem: null,
			currentSelectedChoiceItem: null,
			comboSubItems: [],
			parentVariantDish: null,
		});
		this.props.handleVariantSelection(
			variant
				? {
						...variant,
						title:
							this.props.parentVariantDish.title +
							" - " +
							variant.variant_child_title,
				  }
				: null
		);
		if (variant) {
			this.setState({
				comboTotalPrice: variant.override_parent_price
					? variant.price
					: this.state.parentPrice,
			});
		} else {
			this.setState({
				comboTotalPrice: this.state.parentPrice,
			});
		}
	};

	handleVariantSelection = (variant) => {
		if (!variant) {
			variant = this.state.parentVariantDish;
		}
		this.setState({ currentSelectedChoiceItem: variant });
	};

	truncateParagraph = (paragraph, characterLimit) => {
		if (paragraph.length > characterLimit) {
			return paragraph.substring(0, characterLimit) + "...";
		}
		return paragraph;
	};

	setCurrentSelectedComboItem(comboItem) {
		// const wholeFoodItemList = this.props.wholeFoodItemList;

		// const updatedItemChoices = comboItem.item_choices
		// 	.map((choiceId) => {
		// 		return wholeFoodItemList.find((item) => item.id === choiceId);
		// 	})
		// 	.filter((itemDetail) => itemDetail !== undefined); // Filter out undefined values

		// const updatedComboItem = {
		// 	...comboItem,
		// 	item_choices: updatedItemChoices,
		// };

		// this.setState({
		// 	currentSelectedComboItem: updatedComboItem,
		// });
		try {
			let name = this.props.pathName;
			let inputParams = {
				name,
				items: comboItem.item_choices,
				action_type: "get_bulk_food_items_details",
			};
			this.dataHandler.getFoodProfile(inputParams).then((response) => {
				let updatedItemChoices = response.data.data.filter(
					(itemDetail) =>
						itemDetail !== undefined &&
						((this.props.modeOfDelevery == 0 &&
							itemDetail.is_delivery_enabled) ||
							(this.props.modeOfDelevery == 1 &&
								itemDetail.is_takeout_enabled))
				);
				const updatedComboItem = {
					...comboItem,
					item_choices: updatedItemChoices,
				};

				this.setState({
					currentSelectedComboItem: updatedComboItem,
				});
			});
		} catch (e) {
			const updatedComboItem = {
				...comboItem,
				item_choices: [],
			};

			this.setState({
				currentSelectedComboItem: updatedComboItem,
			});
		}
	}

	setCurrentSelectedChoiceItem(choiceItem) {
		let name = this.props.pathName;
		let inputParams = { name: name, id: choiceItem.id };
		this.dataHandler.getFoodProfile(inputParams).then((response) => {
			let foodObj = response.data.data;
			// console.log("FoodData", foodObj);
			// this.setState({ showMenuProfile: true, itemSelected: foodObj });
			this.setState({
				currentSelectedChoiceItem: foodObj,
				parentVariantDish: foodObj,
			});
		});
	}

	addComboToCart() {
		let combo = {
			id: this.props.itemSelected.id,
			discount: this.props.itemSelected.discount,
			title: this.props.itemSelected.title,
			price: this.state.comboTotalPrice,
			itemPrice: this.props.itemSelected.price,
			is_delivery_enabled: this.props.itemSelected.is_delivery_enabled,
			is_takeout_enabled: this.props.itemSelected.is_takeout_enabled,
			quantity: 1,
			item_suggestion: "",
			upCellItems: [],
			customAnswers: [],
			tax_percent: this.props.itemSelected.tax_percent || 0,
			is_combo: true,
			combo_sub_items: this.state.comboSubItems,
			parent_variant_dish_id: this.props?.parentVariantDish?.id || null,
		};
		this.props.setCustomaize(combo);
		// console.log(combo);
	}

	updateComboTotalPrice() {
		let comboSubItems = this.state.completedComboItems.list.reduce(
			(acc, item) => {
				return acc.concat(item.selected_item_choices);
			},
			[]
		);
		let total = 0;
		for (let i = 0; i < comboSubItems.length; i++) {
			const comboSubItem = comboSubItems[i];

			if (comboSubItem.upCellItems) {
				total =
					comboSubItem.upCellItems.reduce(function (previous, current) {
						return previous + +(current.item_price * current.quantity);
					}, 0) + total;
			}
			if (comboSubItem.customAnswers) {
				total =
					comboSubItem.customAnswers.reduce(function (previous, current) {
						return previous + +current.item_price;
					}, 0) + total;
			}
		}
		total = total + Number(this.props.itemSelected.price);
		// console.log("Total", total);

		this.setState({ comboSubItems, comboTotalPrice: total.toFixed(2) });
	}

	addToCompletedComboItems(comboItemChoice) {
		let comboItem = {
			id: this.state.currentSelectedComboItem.id,

			selected_item_choices: [comboItemChoice],
		};
		// if (!(comboItem.id in this.state.completedComboItems.dict)) {
		let completedComboItemsDictCopy = {
			...this.state.completedComboItems.dict,
		};
		completedComboItemsDictCopy[comboItem.id] = comboItem;
		this.setState(
			{
				completedComboItems: {
					dict: completedComboItemsDictCopy,
					list: convertDictItemsToList(completedComboItemsDictCopy),
				},
			},
			() =>
				this.setState(
					{
						currentSelectedChoiceItem: null,
						currentSelectedComboItem: null,
						parentVariantDish: null,
					},
					this.updateComboTotalPrice
				)
		);
		// }
	}

	render() {
		let image = "img/empty_image.jpeg";
		let enable_image_display = this.props.itemSelected.enable_image_display;
		if (enable_image_display) {
			if (this.props.itemSelected.image) {
				image = window.image_path + this.props.itemSelected.image;
			} else if (this.props.default_food_item_image) {
				image = window.image_path + this.props.default_food_item_image;
			}
		}

		let balanceNumberOfComboItems =
			this.props.itemSelected.combo_choices.length -
			this.state.completedComboItems.list.length;

		// console.log(this.state.currentSelectedComboItem);

		return (
			<>
				<Modal
					show={this.props.showModal}
					onHide={() => {
						this.setState(
							{
								selectedVariant: null,
							},
							this.props.onHide()
						);
					}}
					centered
					style={{
						borderRadius: 6,
						visibility: this.state.currentSelectedChoiceItem
							? "hidden"
							: "visible",
					}}
					contentClassName="ior-modal-content"
				>
					{this.state.currentSelectedComboItem ? (
						<>
							<Modal.Header
								style={{
									alignItems: "center",
									borderBottom: 0,
									// paddingBottom: 1,
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<Modal.Title style={{ textAlign: "left", flex: 1 }}>
									<h5 style={{ color: "grey", margin: 0 }}>
										{this.state.currentSelectedComboItem.title +
											" From Given Choices"}
									</h5>
								</Modal.Title>
								<div
									onClick={() =>
										this.setState({
											currentSelectedComboItem: null,
										})
									}
									style={{
										cursor: "pointer",
										padding: 0,
										margin: 0,
										border: "none",
										background: "none",
										outline: "none",
									}}
								>
									<i
										className="icofont-arrow-left"
										style={{
											fontSize: "23px",
											color: "grey",
											transition: "color 0.3s",
										}}
										onMouseOver={(e) =>
											(e.currentTarget.style.color = "black")
										}
										onMouseOut={(e) =>
											(e.currentTarget.style.color = "grey")
										}
									></i>
								</div>
							</Modal.Header>
							<Modal.Body
								style={{ height: "300px", overflowY: "auto" }}
							>
								<div className="selection-items">
									{this.state.currentSelectedComboItem
										.item_choices.length > 0 ? (
										<div className="d-flex flex-column">
											{this.state.currentSelectedComboItem.item_choices.map(
												(choiceItem, index) => {
													let choiceItemImage =
														"img/empty_image.jpeg";
													let enable_image_display =
														choiceItem.enable_image_display;
													if (enable_image_display) {
														if (choiceItem.image) {
															choiceItemImage =
																window.image_path +
																choiceItem.image;
														} else if (
															this.props
																.default_food_item_image
														) {
															choiceItemImage =
																window.image_path +
																this.props
																	.default_food_item_image;
														}
													}
													return (
														<Card
															key={index}
															className="mb-3"
															style={{
																borderRadius:
																	"10px",
																boxShadow:
																	"0 2px 4px rgba(0, 0, 0, 0.1)",
																transition:
																	"transform 0.2s",
																cursor: "pointer",
																height: "50px",
															}}
															onMouseEnter={(e) =>
																(e.currentTarget.style.transform =
																	"scale(1.02)")
															}
															onMouseLeave={(e) =>
																(e.currentTarget.style.transform =
																	"scale(1)")
															}
															onClick={() =>
																this.setCurrentSelectedChoiceItem(
																	choiceItem
																)
															}
														>
															<Card.Body className="d-flex align-items-center p-2">
																<img
																	src={
																		choiceItemImage
																	} // Assuming you have an image URL in choiceItem
																	alt={
																		choiceItem.title
																	}
																	style={{
																		width: "30px",
																		height: "30px",
																		objectFit:
																			"cover",
																		borderRadius:
																			"5px",
																		marginRight:
																			"10px",
																	}}
																/>
																<div className="mr-auto">
																	<h6
																		style={{
																			margin: 0,
																			fontWeight: 500,
																			color: "#333",
																			fontSize:
																				"14px",
																		}}
																	>
																		{
																			choiceItem.title
																		}
																	</h6>
																</div>
																<Button
																	variant="primary"
																	style={{
																		backgroundColor:
																			"rgb(0, 151, 136)",
																		borderColor:
																			"rgb(0, 151, 136)",
																		borderRadius:
																			"20px",
																		padding:
																			"5px 15px",
																		fontWeight:
																			"bold",
																		fontSize:
																			"12px",
																		transition:
																			"all 0.3s ease",
																		display:
																			"flex",
																		alignItems:
																			"center",
																	}}
																>
																	{/* <i
																	className="icofont-plus"
																	style={{
																		marginRight:
																			"10px",
																		fontSize:
																			"18px",
																		color: "#333",
																	}}
																></i> */}
																	Add
																</Button>
															</Card.Body>
														</Card>
													);
												}
											)}
										</div>
									) : (
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												height: "90%",
												position: "absolute",
												width: "90%",
											}}
										>
											Currently no choices available
										</div>
									)}
								</div>
							</Modal.Body>

							<Modal.Footer
								style={{
									display: "flex",
									justifyContent: "space-around",
									padding: "5px 15px",
									backgroundColor: "rgba(0, 151, 136, 0.1)",
									borderTop: "1px solid rgba(0, 0, 0, 0.1)",
								}}
							>
								{/* <Button
							variant="secondary"
							onClick={this.props.onHide}
							style={{
								backgroundColor: "#f8f9fa",
								color: "#333",
								border: "1px solid #ced4da",
								borderRadius: "20px",
								padding: "8px 16px",
								fontWeight: "bold",
							}}
						>
							Close
						</Button> */}
								<Button
									variant="primary"
									style={{
										backgroundColor: "white",
										border: "none",
										borderRadius: "20px",
										padding: "8px 16px",
										color: "#fff",
										fontWeight: "bold",
										boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
									}}
									onClick={() =>
										this.setState({
											currentSelectedComboItem: null,
										})
									}
								>
									Cancel
								</Button>
							</Modal.Footer>
						</>
					) : (
						<>
							<Modal.Header
								style={{
									alignItems: "center",
									borderBottom: 0,
									paddingBottom: 1,
								}}
								closeButton={
									this.state.selectedVariant ? false : true
								}
							>
								<Modal.Title style={{ textAlign: "left" }}>
									<h5
										style={{
											color: "grey",
											margin: 0,
											display: "flex",
											alignItems: "center",
										}}
									>
										{" "}
										{this.props.itemSelected.title}
										{this.props.itemSelected.is_gluten_free && (
											<Image
												src="/img/gluten_free.png"
												style={{
													width: "20px",
													height: "20px",
													marginLeft: "4px",
												}}
												roundedCircle
											/>
										)}
										{this.props.itemSelected.is_vegan && (
											<Image
												src="/img/vegan.png"
												style={{
													width: "20px",
													height: "20px",
													marginLeft: "4px",
												}}
												roundedCircle
											/>
										)}
										{this.props.itemSelected.is_vegetarian && (
											<Image
												src="/img/vegetarian.png"
												style={{
													width: "20px",
													height: "20px",
													marginLeft: "4px",
												}}
												roundedCircle
											/>
										)}
									</h5>
								</Modal.Title>

								{this.state.selectedVariant && (
									<div
										onClick={() => {
											this.handleVariantChange(null);
										}}
										style={{
											cursor: "pointer",
											padding: 0,
											margin: 0,
											border: "none",
											background: "none",
											outline: "none",
											marginRight: 10,
										}}
									>
										<i
											className="icofont-arrow-left"
											style={{
												fontSize: "23px",
												color: "grey",
												transition: "color 0.3s",
											}}
											onMouseOver={(e) =>
												(e.currentTarget.style.color =
													"black")
											}
											onMouseOut={(e) =>
												(e.currentTarget.style.color =
													"grey")
											}
										></i>
									</div>
								)}
							</Modal.Header>
							<Modal.Header
								style={{
									width: "100%",
									borderBottom: 0,
									...(this.props.itemSelected.description && {
										paddingBottom: 0,
									}),
								}}
							>
								<div
									className="combo-section d-flex "
									style={{
										width: "100%",
										marginBottom: this.props.itemSelected
											.description
											? 5
											: 5,
									}}
								>
									<Image
										src={image}
										rounded
										width={100}
										height={95}
										className="ior-bundle-image-on-modal"
										style={{ borderRadius: "10px !important" }}
									/>
									<div
										className="ml-3"
										style={{
											justifyContent: "center",
											display: "flex",
											flexDirection: "column",
										}}
									>
										{(!this.props.parentVariantDish
											.is_variant_parent ||
											!this.props.parentVariantDish.variants
												.length > 0 ||
											this.state.selectedVariant) && (
											<h5>
												Total Price: ${" "}
												{this.state.comboTotalPrice}
											</h5>
										)}
										{this.props.itemSelected.description && (
											<p style={{ textAlign: "justify" }}>
												{this.truncateParagraph(
													this.props.itemSelected
														.description,
													140
												)}
											</p>
										)}
									</div>
								</div>
							</Modal.Header>

							{(!this.props.parentVariantDish.is_variant_parent ||
								!this.props.parentVariantDish.variants.length > 0 ||
								this.state.selectedVariant) && (
								<Modal.Header
									style={{
										background:
											"linear-gradient(to right, #009788, #00bf72)",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										padding: "10px 20px",
										color: "white",
										margin: "0px 15px",
									}}
								>
									<div
										style={{ color: "white" }}
									>{`${this.state.completedComboItems.list.length} Of ${this.props.itemSelected.combo_choices.length} Completed`}</div>
								</Modal.Header>
							)}

							<Modal.Body
								style={{ height: "300px", overflowY: "auto" }}
							>
								{this.props.parentVariantDish.is_variant_parent &&
									this.props.parentVariantDish.variants.length >
										0 &&
									!this.state.selectedVariant && (
										<div className="d-flex flex-column">
											<VariantTab
												variants={
													this.props.parentVariantDish
														.variants
												}
												variant_group_title={
													this.props.parentVariantDish
														.variant_group_title
												}
												handleVariantChange={
													this.handleVariantChange
												}
												selectedVariant={
													this.state.selectedVariant
												}
											/>
										</div>
									)}

								{(!this.props.parentVariantDish.is_variant_parent ||
									!this.props.parentVariantDish.variants.length >
										0 ||
									this.state.selectedVariant) && (
									<div className="selection-items">
										<div className="d-flex flex-column">
											{this.props.itemSelected.combo_choices.map(
												(comboItem, index) => {
													return comboItem.id in
														this.state
															.completedComboItems
															.dict ? (
														<Accordion>
															<Card
																key={index}
																className="mb-3"
																style={{
																	borderRadius:
																		"10px",
																	boxShadow:
																		"0 2px 4px rgba(0, 0, 0, 0.1)",
																	transition:
																		"transform 0.2s",
																	cursor: "pointer",
																	height: "auto", // Adjusted to accommodate accordion content
																}}
															>
																<Accordion.Toggle
																	as={Card.Header}
																	eventKey={`${index}`}
																	className="ior-combo-card"
																>
																	<div className="d-flex align-items-center justify-content-between">
																		<div className="d-flex align-items-center">
																			<Icofont
																				style={{
																					marginRight:
																						"10px",
																					fontSize:
																						"18px",
																					color: "rgb(0, 151, 136)",
																				}}
																				icon="check-circled" // Tick mark icon
																			/>
																			<Icofont
																				style={{
																					marginRight:
																						"10px",
																					fontSize:
																						"18px",
																					color: "#333",
																				}}
																				icon="arrow-down" // Expand icon
																			/>
																			<h6
																				style={{
																					margin: 0,
																					fontWeight: 500,
																					color: "#333",
																					fontSize:
																						"14px",
																				}}
																			>
																				{`${comboItem.title}`}
																			</h6>
																		</div>
																		<div className="d-flex align-items-center">
																			<Button
																				variant="outline-primary"
																				style={{
																					borderRadius:
																						"20px",
																					padding:
																						"5px 10px",
																					fontWeight:
																						"bold",
																					fontSize:
																						"12px",
																					transition:
																						"all 0.3s ease",
																					display:
																						"flex",
																					alignItems:
																						"center",
																				}}
																				onClick={() =>
																					this.setCurrentSelectedComboItem(
																						comboItem
																					)
																				}
																			>
																				Change
																			</Button>
																		</div>
																	</div>
																</Accordion.Toggle>
																<Accordion.Collapse
																	eventKey={`${index}`}
																>
																	<Card.Body className="p-2">
																		{/* <ul
																		style={{
																			paddingLeft:
																				"20px",
																		}}
																	>
																		{this.state.completedComboItems.dict[
																			comboItem
																				.id
																		].selected_item_choices.map(
																			(
																				item,
																				idx
																			) => (
																				<li
																					key={
																						idx
																					}
																					style={{
																						listStyleType:
																							"disc",
																					}}
																				>
																					{
																						item.title
																					}
																				</li>
																			)
																		)}
																	</ul> */}
																		{/* <ul
																		style={{
																			paddingLeft:
																				"0",
																			listStyleType:
																				"none",
																		}}
																	>
																		{this.state.completedComboItems.dict[
																			comboItem
																				.id
																		].selected_item_choices.map(
																			(
																				item,
																				idx
																			) => (
																				<li
																					key={
																						idx
																					}
																					style={{
																						marginBottom:
																							"15px",
																					}}
																				>
																					<Card
																						style={{
																							border: "0px solid #ddd",
																							borderRadius:
																								"8px",
																							padding:
																								"10px",
																							paddingBottom: 0,
																						}}
																					>
																						<Card.Body
																							style={{
																								padding:
																									"0",
																							}}
																						>
																							<Card.Title
																								style={{
																									fontSize:
																										"0.9rem", // Reduced font size
																									fontWeight:
																										"bold",
																									borderBottom:
																										item
																											.upCellItems
																											.length >
																											0 ||
																										item
																											.customAnswers
																											.length >
																											0
																											? "1px dashed #aaa"
																											: "none", // Thinner border
																									paddingBottom:
																										"5px",
																									marginBottom:
																										"10px",
																									color: "#6c757d", // Grey color
																								}}
																							>
																								{
																									item.title
																								}
																							</Card.Title>
																							{item
																								.upCellItems
																								.length >
																								0 && (
																								<ListGroup
																									variant="flush"
																									style={{
																										paddingLeft:
																											"20px",
																									}}
																								>
																									{item.upCellItems.map(
																										(
																											subitem,
																											subidx
																										) => (
																											<ListGroup.Item
																												key={
																													subidx
																												}
																												style={{
																													display:
																														"flex",
																													justifyContent:
																														"space-between",
																													alignItems:
																														"center",
																													listStyleType:
																														"circle",
																													backgroundColor:
																														"transparent",
																													padding:
																														"5px 0",
																													color: "#555", // Subtle text color
																												}}
																											>
																												<span>
																													{
																														subitem.item_name
																													}
																												</span>
																												<span>
																													{
																														subitem.quantity
																													}{" "}
																													x{" "}
																													{
																														subitem.item_price
																													}
																												</span>
																											</ListGroup.Item>
																										)
																									)}
																								</ListGroup>
																							)}
																							{item
																								.customAnswers
																								.length >
																								0 && (
																								<ListGroup
																									variant="flush"
																									style={{
																										paddingLeft:
																											"20px",
																									}}
																								>
																									{item.customAnswers.map(
																										(
																											subitem,
																											subidx
																										) => (
																											<ListGroup.Item
																												key={
																													subidx
																												}
																												style={{
																													display:
																														"flex",
																													justifyContent:
																														"space-between",
																													alignItems:
																														"center",
																													listStyleType:
																														"circle",
																													backgroundColor:
																														"transparent",
																													padding:
																														"5px 0",
																													color: "#555", // Subtle text color
																												}}
																											>
																												<span>
																													{
																														subitem.answer_title
																													}
																												</span>
																												<span>
																													{
																														1
																													}{" "}
																													x{" "}
																													{
																														subitem.item_price
																													}
																												</span>
																											</ListGroup.Item>
																										)
																									)}
																								</ListGroup>
																							)}
																						</Card.Body>
																					</Card>
																				</li>
																			)
																		)}
																	</ul> */}
																		<ul
																			style={{
																				paddingLeft:
																					"0",
																				listStyleType:
																					"none",
																			}}
																		>
																			{this.state.completedComboItems.dict[
																				comboItem
																					.id
																			].selected_item_choices.map(
																				(
																					item,
																					idx
																				) => (
																					<li
																						key={
																							idx
																						}
																						style={{
																							marginBottom:
																								"15px",
																						}}
																					>
																						<div
																							style={{
																								border: "0px solid #ddd",
																								borderRadius:
																									"8px",
																								padding:
																									"10px",
																								paddingBottom: 0,
																								paddingLeft: 6,
																							}}
																						>
																							<div
																								style={{
																									padding:
																										"0",
																								}}
																							>
																								<h6
																									style={{
																										fontSize:
																											"0.9rem", // Reduced font size
																										fontWeight:
																											"bold",
																										borderBottom:
																											item
																												.upCellItems
																												.length >
																												0 ||
																											item
																												.customAnswers
																												.length >
																												0
																												? "1px dashed #aaa"
																												: "none", // Thinner border
																										paddingBottom:
																											"5px",
																										marginBottom:
																											"10px",
																										color: "#6c757d", // Grey color
																										display:
																											"flex",
																										alignItems:
																											"center",
																									}}
																								>
																									<span
																										style={{
																											marginRight:
																												"8px",
																											listStyleType:
																												"disc",
																											fontSize: 20,
																										}}
																									>
																										•
																									</span>
																									{
																										item.title
																									}
																								</h6>
																								{item
																									.upCellItems
																									.length >
																									0 && (
																									<ul
																										style={{
																											paddingLeft:
																												"20px",
																											listStyleType:
																												"circle",
																										}}
																									>
																										{item.upCellItems.map(
																											(
																												subitem,
																												subidx
																											) => (
																												<li
																													key={
																														subidx
																													}
																													style={{
																														display:
																															"flex",
																														justifyContent:
																															"space-between",
																														alignItems:
																															"center",
																														backgroundColor:
																															"transparent",
																														padding:
																															"5px 0",
																														color: "#555", // Subtle text color
																													}}
																												>
																													<span>
																														{
																															subitem.item_name
																														}
																													</span>
																													<span>
																														{
																															subitem.quantity
																														}{" "}
																														x{" "}
																														{
																															subitem.item_price
																														}
																													</span>
																												</li>
																											)
																										)}
																									</ul>
																								)}
																								{item
																									.customAnswers
																									.length >
																									0 && (
																									<ul
																										style={{
																											paddingLeft:
																												"20px",
																											listStyleType:
																												"circle",
																										}}
																									>
																										{item.customAnswers.map(
																											(
																												subitem,
																												subidx
																											) => (
																												<li
																													key={
																														subidx
																													}
																													style={{
																														display:
																															"flex",
																														justifyContent:
																															"space-between",
																														alignItems:
																															"center",
																														backgroundColor:
																															"transparent",
																														padding:
																															"5px 0",
																														color: "#555", // Subtle text color
																													}}
																												>
																													<span>
																														{
																															subitem.answer_title
																														}
																													</span>
																													<span>
																														1
																														x{" "}
																														{
																															subitem.item_price
																														}
																													</span>
																												</li>
																											)
																										)}
																									</ul>
																								)}
																							</div>
																						</div>
																					</li>
																				)
																			)}
																		</ul>
																	</Card.Body>
																</Accordion.Collapse>
															</Card>
														</Accordion>
													) : (
														<Card
															key={index}
															className="mb-3"
															style={{
																borderRadius:
																	"10px",
																boxShadow:
																	"0 2px 4px rgba(0, 0, 0, 0.1)",
																transition:
																	"transform 0.2s",
																cursor: "pointer",
																height: "50px",
															}}
															onMouseEnter={(e) =>
																(e.currentTarget.style.transform =
																	"scale(1.02)")
															}
															onMouseLeave={(e) =>
																(e.currentTarget.style.transform =
																	"scale(1)")
															}
															onClick={() =>
																this.setCurrentSelectedComboItem(
																	comboItem
																)
															}
														>
															<Card.Body className="d-flex align-items-center p-2">
																{/* <Icofont
														style={{
															marginRight: "10px",
															fontSize: "18px",
															color: "#333",
														}}
													/> */}
																{/* <Icofont
													style={{
														marginRight: "10px",
														fontSize: "18px",
														color: "#333",
													}}
													icon="radio-checked"
												/> */}
																<div className="mr-auto">
																	<h6
																		style={{
																			margin: 0,
																			fontWeight: 500,
																			color: "#333",
																			fontSize:
																				"14px",
																		}}
																	>
																		{`${comboItem.title}`}
																	</h6>
																</div>
																<Button
																	variant="primary"
																	style={{
																		backgroundColor:
																			"rgb(0, 151, 136)",
																		borderColor:
																			"rgb(0, 151, 136)",
																		borderRadius:
																			"20px",
																		padding:
																			"5px 15px",
																		fontWeight:
																			"bold",
																		fontSize:
																			"12px",
																		transition:
																			"all 0.3s ease",
																		display:
																			"flex",
																		alignItems:
																			"center",
																	}}

																	// onMouseEnter={(e) => {
																	// 	e.target.style.backgroundColor =
																	// 		"#00A388";
																	// 	e.target.style.transform =
																	// 		"scale(1.1)";
																	// }}
																	// onMouseLeave={(e) => {
																	// 	e.target.style.backgroundColor =
																	// 		"rgb(0, 151, 136)";
																	// 	e.target.style.transform =
																	// 		"scale(1)";
																	// }}
																>
																	{/* <Icofont
																	style={{
																		marginRight:
																			"10px",
																		fontSize:
																			"18px",
																		color: "#333",
																	}}
																	icon="food-basket"
																/> */}
																	Select
																</Button>
															</Card.Body>
														</Card>
													);
												}
											)}
										</div>
									</div>
								)}
							</Modal.Body>
							{(!this.props.parentVariantDish.is_variant_parent ||
								!this.props.parentVariantDish.variants.length > 0 ||
								this.state.selectedVariant) && (
								<Modal.Footer
									style={{
										display: "flex",
										justifyContent: "space-around",
										padding: "5px 15px",
										backgroundColor: "rgba(0, 151, 136, 0.1)",
										borderTop: "1px solid rgba(0, 0, 0, 0.1)",
									}}
								>
									{/* <Button
							variant="secondary"
							onClick={this.props.onHide}
							style={{
								backgroundColor: "#f8f9fa",
								color: "#333",
								border: "1px solid #ced4da",
								borderRadius: "20px",
								padding: "8px 16px",
								fontWeight: "bold",
							}}
						>
							Close
						</Button> */}

									<Button
										disabled={balanceNumberOfComboItems > 0}
										variant={
											balanceNumberOfComboItems > 0
												? "danger"
												: "primary"
										}
										style={{
											backgroundColor: "#007bff",
											border: "none",
											borderRadius: "20px",
											padding: "8px 16px",
											color: "#fff",
											fontWeight: "bold",
											boxShadow:
												"0 4px 8px rgba(0, 0, 0, 0.1)",
											width: "100%",
										}}
										onClick={this.addComboToCart}
									>
										{balanceNumberOfComboItems === 0
											? "Add To Cart"
											: balanceNumberOfComboItems ===
											  this.props.itemSelected.combo_choices
													.length
											? `${balanceNumberOfComboItems} Items Required`
											: `${balanceNumberOfComboItems} More Items Required`}
									</Button>
								</Modal.Footer>
							)}
						</>
					)}
				</Modal>
				{this.state.currentSelectedChoiceItem && (
					<ViewMenuDiscriptionModel
						// decreaseItem={this.props.decreaseItem}
						// incrementItem={this.props.incrementItem}
						show={true}
						onHide={() =>
							this.setState({ currentSelectedChoiceItem: null })
						}
						itemSelected={this.state.currentSelectedChoiceItem}
						setCustomaize={this.addToCompletedComboItems}
						default_food_item_image={this.props.default_food_item_image}
						isComboItemView
						parentVariantDish={this.state.parentVariantDish}
						handleVariantSelection={this.handleVariantSelection}
					/>
				)}
			</>
		);
	}
}

// class BundleItemSelectionModal extends React.Component {
// 	constructor(props, context) {
// 		super(props, context);
// 		this.state = {
// 			selectedChoices: {},
// 			currentSelection: null,
// 		};
// 	}

// 	// handleChoiceSelection(selectionTitle, choiceTitle) {
// 	// 	this.setState((prevState) => ({
// 	// 		selectedChoices: {
// 	// 			...prevState.selectedChoices,
// 	// 			[selectionTitle]: choiceTitle,
// 	// 		},
// 	// 		currentSelection: null, // Go back to item selection view
// 	// 	}));
// 	// }

// 	// // Method to handle item selection
// 	// handleItemSelection(selectionTitle) {
// 	// 	this.setState({ currentSelection: selectionTitle });
// 	// }

// 	render() {
// 		const { selectedChoices, currentSelection } = this.state;
// 		let image = null;
// 		let enable_image_display = this.props.itemSelected.enable_image_display;
// 		if (enable_image_display) {
// 			if (this.props.itemSelected.image) {
// 				image = window.image_path + this.props.itemSelected.image;
// 			} else if (this.props.default_food_item_image) {
// 				image = window.image_path + this.props.default_food_item_image;
// 			}
// 		}

// 		return (
// 			<>
// 				<Modal
// 					show={this.props.showModal}
// 					onHide={this.props.onHide}
// 					centered
// 					style={{ borderRadius: 6 }}
// 					contentClassName="ior-modal-content"
// 				>
// 					<Modal.Header
// 						style={{
// 							alignItems: "center",
// 							justifyContent: "center",
// 							borderBottom: 0,
// 							paddingBottom: 2,
// 						}}
// 						closeButton
// 					>
// 						<Modal.Title style={{ textAlign: "center" }}>
// 							<h5 style={{ color: "grey", margin: 0 }}>
// 								{this.props.itemSelected.title}
// 							</h5>
// 						</Modal.Title>
// 					</Modal.Header>
// 					<Modal.Header
// 						style={{
// 							width: "100%",
// 							paddingBottom: 0,
// 							borderBottom: 0,
// 						}}
// 						// // closeButton
// 					>
// 						<div
// 							className="combo-section d-flex "
// 							style={{
// 								width: "100%",
// 								marginBottom: 5,
// 							}}
// 						>
// 							<Image
// 								src={image}
// 								rounded
// 								width={100}
// 								height={95}
// 								className="ior-bundle-image-on-modal"
// 								style={{ borderRadius: "10px !important" }}
// 							/>
// 							<div
// 								className="ml-3"
// 								style={{
// 									justifyContent: "center",
// 									display: "flex",
// 									flexDirection: "column",
// 								}}
// 							>
// 								<h5>
// 									Total Price: $ {this.props.itemSelected.price}
// 								</h5>
// 								{this.props.itemSelected.description && (
// 									<p style={{ textAlign: "justify" }}>
// 										{this.props.itemSelected.description}
// 									</p>
// 								)}
// 							</div>
// 						</div>
// 					</Modal.Header>

// 					<Modal.Header
// 						style={{
// 							background:
// 								"linear-gradient(to right, #009788, #00bf72)",
// 							display: "flex",
// 							justifyContent: "space-between",
// 							alignItems: "center",
// 							padding: "10px 20px", // Adjust padding as needed
// 							color: "white", // Text color for the header
// 							margin: "0px 15px",
// 						}}
// 					>
// 						<div style={{ display: "flex", alignItems: "center" }}>
// 							<div
// 								style={{
// 									background:
// 										"linear-gradient(to right, #009788, #00bf72)",
// 									width: 40,
// 									borderRadius: 5,
// 									textAlign: "center",
// 								}}
// 							>
// 								20%
// 							</div>
// 						</div>
// 						<div style={{ color: "white" }}>3 More Items Required</div>
// 					</Modal.Header>

// 					<Modal.Body style={{ height: "300px", overflowY: "auto" }}>
// 						{/* <div className="combo-section d-flex mb-3">
// 							<Image src={data.combo_image} rounded width={100} />
// 							<div className="ml-3">
// 								<h5>Total Price: {data.combo_price}</h5>
// 								<p>Combo Description here...</p>
// 							</div>
// 						</div> */}

// 						<div
// 							className="selection-items"
// 							// style={{ background: "white" }}
// 						>
// 							{/* {data.item_selections.map((selection, index) => (
// 									<ListGroup key={index} className="mb-3">
// 										<ListGroup.Item className="d-flex align-items-center">
// 											<Image
// 												src={selection.choices[0].image}
// 												thumbnail
// 												width={50}
// 												className="mr-3"
// 											/>
// 											<div className="mr-auto">
// 												<h5>{selection.title}</h5>
// 											</div>
// 											<Button
// 												variant="primary"
// 												style={{
// 													backgroundColor:
// 														"rgb(85 119 115)",
// 												}}
// 												onClick={() =>
// 													this.handleItemSelection(
// 														selection.title
// 													)
// 												}
// 											>
// 												ADD
// 											</Button>
// 										</ListGroup.Item>
// 									</ListGroup>
// 								))} */}
// 							{/* <div className="d-flex flex-column">
// 									{data.item_selections.map(
// 										(selection, index) => (
// 											<Card
// 												key={index}
// 												className="mb-3"
// 												style={{
// 													borderRadius: "10px",
// 													boxShadow:
// 														"0 2px 4px rgba(0, 0, 0, 0.1)",
// 													transition: "transform 0.2s",
// 													cursor: "pointer",
// 													height: "100px",
// 												}}
// 												onMouseEnter={(e) =>
// 													(e.currentTarget.style.transform =
// 														"scale(1.02)")
// 												}
// 												onMouseLeave={(e) =>
// 													(e.currentTarget.style.transform =
// 														"scale(1)")
// 												}
// 											>
// 												<Card.Body className="d-flex align-items-center p-2">
// 													<Image
// 														src={
// 															selection.choices[0]
// 																.image
// 														}
// 														thumbnail
// 														width={60}
// 														className="mr-3"
// 														style={{
// 															borderRadius: "10px",
// 														}}
// 													/>
// 													<div className="mr-auto">
// 														<h6
// 															style={{
// 																margin: 0,
// 																fontWeight: 500,
// 																color: "#333",
// 																fontSize: "14px",
// 															}}
// 														>
// 															{selection.title}
// 														</h6>
// 													</div>
// 													<Button
// 														variant="primary"
// 														style={{
// 															backgroundColor:
// 																"rgb(0, 151, 136)",
// 															borderColor:
// 																"rgb(0, 151, 136)",
// 															borderRadius: "20px",
// 															padding: "5px 10px",
// 															fontWeight: "bold",
// 															fontSize: "12px",
// 														}}
// 														onClick={() =>
// 															this.handleItemSelection(
// 																selection.title
// 															)
// 														}
// 													>
// 														Add
// 													</Button>
// 												</Card.Body>
// 											</Card>
// 										)
// 									)}
// 								</div> */}
// 							{/* <div className="d-flex flex-column">
// 									{data.item_selections.map(
// 										(selection, index) => (
// 											<Card
// 												key={index}
// 												className="mb-3"
// 												style={{
// 													borderRadius: "10px",
// 													boxShadow:
// 														"0 2px 4px rgba(0, 0, 0, 0.1)",
// 													transition: "transform 0.2s",
// 													cursor: "pointer",
// 													height: "50px",
// 												}}
// 												onMouseEnter={(e) =>
// 													(e.currentTarget.style.transform =
// 														"scale(1.02)")
// 												}
// 												onMouseLeave={(e) =>
// 													(e.currentTarget.style.transform =
// 														"scale(1)")
// 												}
// 											>
// 												<Card.Body className="d-flex align-items-center p-2">
// 													<div className="mr-auto">
// 														<h6
// 															style={{
// 																margin: 0,
// 																fontWeight: 500,
// 																color: "#333",
// 																fontSize: "14px",
// 															}}
// 														>
// 															{selection.title}
// 														</h6>
// 													</div>
// 													<Button
// 														variant="primary"
// 														style={{
// 															backgroundColor:
// 																"rgb(0, 151, 136)",
// 															borderColor:
// 																"rgb(0, 151, 136)",
// 															borderRadius: "20px",
// 															padding: "5px 10px",
// 															fontWeight: "bold",
// 															fontSize: "12px",
// 														}}
// 														onClick={() =>
// 															this.handleItemSelection(
// 																selection.title
// 															)
// 														}
// 													>
// 														Add
// 													</Button>
// 												</Card.Body>
// 											</Card>
// 										)
// 									)}
// 								</div> */}
// 							<div className="d-flex flex-column">
// 								{this.props.itemSelected.combo_choices.map(
// 									(selection, index) => (
// 										<Card
// 											key={index}
// 											className="mb-3"
// 											style={{
// 												borderRadius: "10px",
// 												boxShadow:
// 													"0 2px 4px rgba(0, 0, 0, 0.1)",
// 												transition: "transform 0.2s",
// 												cursor: "pointer",
// 												height: "50px",
// 											}}
// 											onMouseEnter={(e) =>
// 												(e.currentTarget.style.transform =
// 													"scale(1.02)")
// 											}
// 											onMouseLeave={(e) =>
// 												(e.currentTarget.style.transform =
// 													"scale(1)")
// 											}
// 										>
// 											<Card.Body className="d-flex align-items-center p-2">
// 												{/* <Icofont
// 														style={{
// 															marginRight: "10px",
// 															fontSize: "18px",
// 															color: "#333",
// 														}}
// 													/> */}
// 												<Icofont
// 													style={{
// 														marginRight: "10px",
// 														fontSize: "18px",
// 														color: "#333",
// 													}}
// 													icon="radio-checked"
// 												/>{" "}
// 												<div className="mr-auto">
// 													<h6
// 														style={{
// 															margin: 0,
// 															fontWeight: 500,
// 															color: "#333",
// 															fontSize: "14px",
// 														}}
// 													>
// 														{selection.title}
// 													</h6>
// 												</div>
// 												<Button
// 													variant="primary"
// 													style={{
// 														backgroundColor:
// 															"rgb(0, 151, 136)",
// 														borderColor:
// 															"rgb(0, 151, 136)",
// 														borderRadius: "20px",
// 														padding: "5px 15px",
// 														fontWeight: "bold",
// 														fontSize: "12px",
// 														transition: "all 0.3s ease",
// 														display: "flex",
// 														alignItems: "center",
// 													}}
// 													onClick={() =>
// 														this.handleItemSelection(
// 															selection.title
// 														)
// 													}
// 													onMouseEnter={(e) => {
// 														e.target.style.backgroundColor =
// 															"#00A388";
// 														e.target.style.transform =
// 															"scale(1.1)";
// 													}}
// 													onMouseLeave={(e) => {
// 														e.target.style.backgroundColor =
// 															"rgb(0, 151, 136)";
// 														e.target.style.transform =
// 															"scale(1)";
// 													}}
// 												>
// 													<Icofont
// 														style={{
// 															marginRight: "10px",
// 															fontSize: "18px",
// 															color: "#333",
// 														}}
// 														icon="food-basket"
// 													/>
// 													Select
// 												</Button>
// 											</Card.Body>
// 										</Card>
// 									)
// 								)}
// 							</div>
// 						</div>
// 					</Modal.Body>
// 					<Modal.Footer
// 						style={{
// 							display: "flex",
// 							justifyContent: "space-around",
// 							padding: "5px 15px",
// 							backgroundColor: "rgba(0, 151, 136, 0.1)",
// 							borderTop: "1px solid rgba(0, 0, 0, 0.1)",
// 						}}
// 					>
// 						{/* <Button
// 							variant="secondary"
// 							onClick={this.props.onHide}
// 							style={{
// 								backgroundColor: "#f8f9fa",
// 								color: "#333",
// 								border: "1px solid #ced4da",
// 								borderRadius: "20px",
// 								padding: "8px 16px",
// 								fontWeight: "bold",
// 							}}
// 						>
// 							Close
// 						</Button> */}
// 						<Button
// 							variant="primary"
// 							style={{
// 								backgroundColor: "#007bff",
// 								border: "none",
// 								borderRadius: "20px",
// 								padding: "8px 16px",
// 								color: "#fff",
// 								fontWeight: "bold",
// 								boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
// 							}}
// 						>
// 							Continue
// 						</Button>
// 					</Modal.Footer>
// 				</Modal>
// 			</>
// 		);
// 	}
// }

export default BundleItemSelectionModal;
