import React from "react";
import { Card, Button } from "react-bootstrap";

class VariantTab extends React.Component {
	constructor(props, context) {
		super(props, context);
	}

	handleOnChange = (variant) => {
		this.props.handleVariantChange(variant);
	};

	render() {
		return this.props.variants.map((variant, index) => (
			<Card
				key={index}
				className="mb-3"
				style={{
					borderRadius: "10px",
					boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
					transition: "transform 0.2s",
					cursor: "pointer",
					height: "50px",
				}}
				onMouseEnter={(e) =>
					(e.currentTarget.style.transform = "scale(1.02)")
				}
				onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
				onClick={() => this.handleOnChange(variant)}
			>
				<Card.Body className="d-flex align-items-center p-2">
					{/* <Icofont
														style={{
															marginRight: "10px",
															fontSize: "18px",
															color: "#333",
														}}
													/> */}
					{/* <Icofont
													style={{
														marginRight: "10px",
														fontSize: "18px",
														color: "#333",
													}}
													icon="radio-checked"
												/> */}
					<div className="mr-auto">
						<h6
							style={{
								margin: 0,
								fontWeight: 500,
								color: "#333",
								fontSize: "14px",
							}}
						>
							{`${variant.variant_child_title}`}
						</h6>
					</div>
					<Button
						variant="primary"
						style={{
							backgroundColor: "rgb(0, 151, 136)",
							borderColor: "rgb(0, 151, 136)",
							borderRadius: "20px",
							padding: "5px 15px",
							fontWeight: "bold",
							fontSize: "12px",
							transition: "all 0.3s ease",
							display: "flex",
							alignItems: "center",
						}}

						// onMouseEnter={(e) => {
						// 	e.target.style.backgroundColor =
						// 		"#00A388";
						// 	e.target.style.transform =
						// 		"scale(1.1)";
						// }}
						// onMouseLeave={(e) => {
						// 	e.target.style.backgroundColor =
						// 		"rgb(0, 151, 136)";
						// 	e.target.style.transform =
						// 		"scale(1)";
						// }}
					>
						{/* <Icofont
																	style={{
																		marginRight:
																			"10px",
																		fontSize:
																			"18px",
																		color: "#333",
																	}}
																	icon="food-basket"
																/> */}
						Select
					</Button>
				</Card.Body>
			</Card>
		));
	}
}

export default VariantTab;
